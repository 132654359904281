import React from 'react';
import { Container } from './styles';
import { formatWeight } from '../../../../../../utils/formatValues';
import { Manifest } from '../../../../../../types/business';

interface IExpandedTableContentProps {
  romaneios: Manifest[];
}

const ExpandedTableContent: React.FC<IExpandedTableContentProps> = ({
  romaneios
}) => {
  return (
    <Container>
      <ul>
        {romaneios.map((rom) => (
          <li key={rom.id}>
            <span className="title">Romaneio</span>
            <div>
              <span>{rom.name}</span>
              <span>{formatWeight(rom.weight_kg)}</span>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default ExpandedTableContent;
