import React, { useState } from 'react';
import { Container, THead, TBody } from './styles';
import { Button, Icon } from '@gerdau/hefesto';

type IAppTableCollumn = {
  title: string | React.ReactNode;
};

type IAppTableRow = {
  tds: {
    content: string | React.ReactNode;
    align?: 'left' | 'right';
  }[];
  expandableContent?: React.ReactNode;
};

interface IAppTableProps {
  columns: IAppTableCollumn[];
  rows: IAppTableRow[];
}

export const AppTable: React.FC<IAppTableProps> = ({ columns, rows }) => {
  return (
    <Container>
      <table>
        <THead>
          <tr>
            {columns.map((c, idx) => (
              <th key={idx}>{c.title}</th>
            ))}
          </tr>
        </THead>
        <TBody>
          {rows.map((r, idx) => {
            return <AppTableRow key={idx} {...r} startExpanded={idx === 0} />;
          })}
          {rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <>Inicie conferindo uma etiqueta de suas entregas</>
              </td>
            </tr>
          )}
        </TBody>
      </table>
    </Container>
  );
};

interface IAppTableRowProps extends IAppTableRow {
  startExpanded?: boolean;
}

const AppTableRow = ({
  tds,
  expandableContent,
  startExpanded
}: IAppTableRowProps) => {
  const [expandableOpen, setExpandableOpen] = useState(startExpanded || false);
  return (
    <>
      <tr className={expandableOpen ? 'expanded' : ''}>
        {tds.map((td, i) => (
          <td key={i}>
            <div style={{ justifyContent: td.align }}>
              {expandableContent && i === 0 && (
                <Button
                  name="expand-row"
                  variant="tertiary"
                  onClick={() => setExpandableOpen(!expandableOpen)}
                  icon={
                    <Icon
                      option={expandableOpen ? 'ChevronDown' : 'ChevronRight'}
                    />
                  }
                />
              )}
              {td.content}
            </div>
          </td>
        ))}
      </tr>
      {expandableContent && expandableOpen && (
        <tr>
          <td colSpan={tds.length}>{expandableContent}</td>
        </tr>
      )}
    </>
  );
};

export default AppTable;
