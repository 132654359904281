import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  & > header {
    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;

      & > button {
        max-width: ${({ theme }) => theme.breakpoints.mobileL};
      }
    }
  }

  & span.subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary_color_01_300};
    font-weight: bold;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;

    & > hr {
      border: 1px solid ${({ theme }) => theme.colors.secondary_color_03_100};
      margin: 1rem 0;
    }
  }
`;
