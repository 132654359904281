import React from 'react';
import ActionsFooter from '../ActionsFooter';
import { TicketFormProps } from '..';
import { FieldsContainer } from '../styles';
import { Select, TextArea } from '@gerdau/hefesto';

function TicketPage2({
  ticketForm,
  setTicketForm,
  onFinish,
  loading
}: TicketFormProps) {
  const isAProductIssue = ticketForm.q6.includes('produto');

  function disabledNextButton(): boolean {
    const { q7, q8, q9 } = ticketForm;
    if (isAProductIssue) {
      return q7 === '' || q8 === '';
    } else {
      return q9 === '';
    }
  }

  return (
    <>
      <FieldsContainer>
        <h5>{ticketForm.q6}</h5>
        {isAProductIssue && (
          <>
            <Select
              name="q7"
              value={ticketForm.q7}
              disabled={loading}
              onSelect={(selected) =>
                setTicketForm((prev) => ({
                  ...prev,
                  q7: selected as string
                }))
              }
              label="Onde o problema foi detectado?"
              options={[
                'Na montagem da armação',
                'Na conferência do recebimento'
              ].map((item) => ({ value: item, label: item }))}
            />

            <Select
              name="q8"
              value={ticketForm.q8}
              disabled={loading}
              onSelect={(selected) =>
                setTicketForm((prev) => ({
                  ...prev,
                  q8: selected as string,
                  q9: '',
                  q10: '',
                  q11: '',
                  q12: '',
                  q13: '',
                  q14: '',
                  q15: '',
                  q16: '',
                  q17: ''
                }))
              }
              label="Qual o tipo de problema com o material?"
              options={[
                'Quantidade (falta e outros)',
                'Troca de bitola',
                'Erro dimensional',
                'Revisão errada',
                'Outros'
              ].map((item) => ({ value: item, label: item }))}
            />
          </>
        )}

        {!isAProductIssue && (
          <>
            <TextArea
              name="q9"
              cols={300}
              label="Descreva o ocorrido (faturamento)"
              placeholder="Insira sua resposta"
              value={ticketForm.q9}
              disabled={loading}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q9: e.target.value }))
              }
            />
          </>
        )}
      </FieldsContainer>

      <ActionsFooter
        ticketForm={ticketForm}
        setTicketForm={setTicketForm}
        disabledBackButton={loading}
        disabledNextButton={loading || disabledNextButton()}
        lastPage={!isAProductIssue}
        onFinish={onFinish}
      />
    </>
  );
}

export default TicketPage2;
