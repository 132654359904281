import { createContext } from 'react';
import { InitialDataResponse } from '../types/communication';
import { Complain, DeliveryRoll, Sequence } from '../types/business';

export interface CDBConferenceTheme {
  colors: {
    navBar: {
      background: string;
      text: string;
    };
  };
}

export interface IUser {
  name: string;
  email: string;
}
export interface CDBConferenceAuthContext {
  loginAs(user: IUser | undefined): Promise<void>;
  logout(): Promise<void>;
  user?: IUser;
}

export interface SelectInputOption {
  id: React.Key;
  label: string;
}

export type SelectInputCallback = (selectedOption: SelectInputOption) => void;
export type SelectInputCancelCallback = () => void;
export interface SelectInputActions {
  open(
    callBack: SelectInputCallback,
    cancelCallback: SelectInputCancelCallback,
    options: SelectInputOption[],
    title: string,
    selectedValue?: React.Key
  ): void;
}
export interface ICDBConferenceContext {
  auth: CDBConferenceAuthContext;
  conference: CDBConferenceContext;
  data: InitialDataResponse;
  computed: ComputedData;
  loading: ILoadingOverlay;
}

interface ILoadingOverlay {
  show: () => void;
  hide: () => void;
}

export interface ComputedData {
  profile: IUser;
  toCheck: DeliveryRoll[];
  checked: DeliveryRoll[];

  complains: Complain[];
}

export interface CDBConferenceContext {
  updateSequenceStatus(
    sequence: Sequence,
    deliveryRollId: string,
    romaneioId: string
  ): void;
  appendComplain(complain: Complain): void;

  appendDeliveryRoll(deliverRoll: DeliveryRoll): void;
}

const CDBConferenceContext = createContext({} as ICDBConferenceContext);

export default CDBConferenceContext;
