import styled from 'styled-components';

export const AppContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100% - (3.5rem * 2));
  margin: auto;
  max-width: ${({ theme }) => theme.breakpoints.laptopL};
  top: 3.5rem;
  position: relative;

  & div.app-content {
    overflow: hidden;
    height: 100%;
  }
`;
