import React from 'react';
import { createPortal } from 'react-dom';
import { Container, LoadingBar, Paragraph } from './styles';
import { svg } from '../../assets/styles/images/logo';

function LoaderBox() {
  return createPortal(
    <Container>
      <div>
        <Paragraph>
          <small>Por favor, aguarde.</small>
        </Paragraph>

        {svg}

        <LoadingBar>
          <div></div>
        </LoadingBar>

        <Paragraph>
          <small>Estamos carregando os dados...</small>
        </Paragraph>
      </div>
    </Container>,
    document.body
  );
}

export default LoaderBox;
