import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body, #root {
    font-size: 16px;
    height: 100%;
    font-family: ${(props) => props.theme.fonts.workSans};
    background-color: ${(props) => props.theme.colors.background};
  }

  // hefesto custom style
  .hefesto-tab .ant-tabs-top > .ant-tabs-nav,
  .hefesto-tab .ant-tabs-top > div > .ant-tabs-nav {
    margin: 0;
  }

  .hefesto-tab__frame .ant-tabs-tab-btn {
    padding: 0 0.125rem;
  }

  .hefesto-tab__content {
    gap: unset;

    & > .hefesto-tab__name {
      font-size: 0.875rem;

      @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.tablet}) {
        font-size: 1rem;
      }
    }
  }
`;
