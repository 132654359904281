import styled from 'styled-components';

export const DeliveryConferenceCard = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.secondary_color_03_50};
  min-width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
  box-shadow: 0px 3.5px 5.25px -3.5px rgba(24, 39, 75, 0.12),
    0px 7px 7px -3.5px rgba(24, 39, 75, 0.08);
  border-radius: 8px;

  flex-direction: column;

  & > div {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;

      & > span {
        display: block;

        & ~ span {
          margin-top: 0.5rem;
        }
      }
    }

    &.actions {
      margin-top: 1rem;
      gap: 0.5rem;

      & > button {
        flex-grow: 1;
      }
    }
  }
`;
