import styled from 'styled-components';

export const FormContainer = styled.section`
  display: flex;
  flex-direction: column;

  & h5 {
    color: ${({ theme }) => theme.colors.primary_color_01_800};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.875rem;

    &:first-child {
      padding: 0 1rem;
      margin-bottom: 0.25rem;
    }
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.secondary_color_03_50};
  padding: 1rem;
  margin: 0.5rem 0;
  gap: 1rem;

  & .hefesto-textarea,
  .hefesto-textarea > textarea {
    max-width: 100%;
  }
`;
