import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
`;
