import React, { useContext } from 'react';
import { AppTable } from '../../../../../components/AppTable';
import { Sequence, SequenceStatus } from '../../../../../types/business';
import TagImage from '../../../../../components/TagImage';
import OptionsMenu from '../../../../../components/OptionsMenu';
import { Button, Icon } from '@gerdau/hefesto';
import CDBConferenceContext from '../../../../../contexts/app';
import {
  ENotificationTypes,
  useNotifications
} from '../../../../../contexts/notifications';
import Sdk from '../../../../../sdk';
interface IFoundItems {
  sequences: Sequence[];
  searchValue: string;
}

const Found: React.FC<IFoundItems> = ({ sequences, searchValue }) => {
  const context = useContext(CDBConferenceContext);
  const { addNotification } = useNotifications();
  function filterSequences(sequence: Sequence): boolean {
    return sequence.number.toString().includes(searchValue);
  }

  async function markAsNotFound(sequence: Sequence): Promise<void> {
    try {
      context.loading.show();
      const response = await Sdk.changeTagStatus(
        sequence,
        SequenceStatus.Missing
      );
      if (response.success) {
        context.conference.updateSequenceStatus(
          response.modified_sequence.sequence,
          response.modified_sequence.delivery_roll_id,
          response.modified_sequence.romaneio_id
        );
        switch (response.modified_sequence.sequence.status) {
          case SequenceStatus.Missing:
            addNotification(
              'Item marcado como não encontrado',
              ENotificationTypes.DANGER,
              3000
            );
            break;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error({ error });
      if (error.response?.status !== 401) {
        addNotification(
          'Falha ao conferir o item.',
          ENotificationTypes.DANGER,
          3000
        );
      }
    } finally {
      context.loading.hide();
    }
  }

  return (
    <AppTable
      columns={[
        { title: 'Formato' },
        { title: 'Sequência' },
        { title: 'Quantidade' },
        { title: '' }
      ]}
      rows={sequences.filter(filterSequences).map((sequence) => ({
        tds: [
          {
            content: <TagImage id={sequence.picture} />
          },
          { content: sequence.number },
          {
            content: sequence.quantity
          },
          {
            content: (
              <OptionsMenu
                items={[
                  {
                    title: 'Não encontrado',
                    onClick: () => markAsNotFound(sequence)
                  }
                ]}
              >
                <Button
                  name="check-btn"
                  variant="tertiary"
                  icon={<Icon option="MoreVertical" />}
                  iconPosition="right"
                  children=""
                />
              </OptionsMenu>
            ),
            align: 'right'
          }
        ]
      }))}
    />
  );
};

export default Found;
