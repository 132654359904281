import React, { useState } from 'react';
import { Container } from './styles';
import { Button, Dialog, Icon, Title } from '@gerdau/hefesto';
import CollapseItem from './components/CollapseItem';

interface IFrequentlyAskedQuestions {
  question: string;
  answer: string;
  link?: string;
}

export const Settings: React.FC = () => {
  const [frequentlyAskedQuestions] = useState<IFrequentlyAskedQuestions[]>([
    {
      question: 'Como libero acesso para um novo usuário?',
      answer:
        'O acesso é liberado pelo Gerdau mais na área de Gestão de usuários selecionando a opção de “Usuário de Corte e Dobra”. Caso a obra não tenha acesso à área logada do Gerdau mais entrar em contato com a equipe comercial.'
    },
    {
      question:
        'Algumas etiquetas estão danificadas ou não tem Código de barras, como confiro?',
      answer: `É possível conferir manualmente, basta localizar o número da sequência e utilizar a barra de pesquisa e clicar botão encontrado. Lembre-se de encerrar a pesquisa para voltar a enxergar as demais sequências no aplicativo.`
    },
    {
      question: 'Perdi os documentos, onde encontro?',
      answer:
        'Nas entregas acompanham os seguintes documentos impressos: Nota Fiscal, lista resumida, lista completa, boleto e certificado de qualidade. A nota fiscal fica disponível no Gerdau mais, caso necessite de uma cópia dos demais documentos, favor entrar em contato com equipe de programação.'
    },
    {
      question: 'Consigo usar o aplicativo no computador?',
      answer: 'Sim, tem disponível a versão web pelo site:',
      link: 'https://gerdaupro.gerdau.digital'
    },
    {
      question: 'Quanto tempo tenho para conferir o material?',
      answer:
        'Até 3 dias úteis para conferir e iniciar uma manifestação por falta de material.'
    },
    {
      question:
        'Após a abertura de manifestação, quanto tempo leva para o retorno?',
      answer:
        'A análise leva em média 4 dias úteis. O retorno será por e-mail, ligação ou Whats App, no aplicativo é apenas aberta a Manifestação.'
    },
    {
      question: 'Quem pode conferir o material?',
      answer: 'Usuários cadastrados no Gerdau mais.'
    },
    {
      question:
        'Ao conferir, aparece a mensagem de que a etiqueta não pertence ao meu contrato.',
      answer:
        'Confira se realmente o material é solicitação da obra, caso seja, ele pode ter sido produzido por uma unidade que ainda não tem disponibilidade de conferência via aplicativo'
    }
  ]);

  const [canShowRedirectConfirmModal, setCanShowRedirectConfirmModal] =
    useState(false);
  return (
    <Container>
      <header>
        <Title level={2}>FAQ</Title>

        <div>
          <span className="subtitle">Gerenciamento de usuários</span>
          <Button
            name="user-management"
            icon={<Icon option="ArrowRight" />}
            iconPosition="right"
            onClick={() => setCanShowRedirectConfirmModal(true)}
          >
            Gerenciar usuários
          </Button>

          {canShowRedirectConfirmModal && (
            <Dialog
              textContent="Você será redirecionado para a página de Gestão de usuários dentro do Gerdau mais."
              actions={{
                right: {
                  name: 'confirm',
                  children: 'Continuar',
                  onClick: () => {
                    setCanShowRedirectConfirmModal(false);
                    window.location.href = process.env
                      .REACT_APP_GMAIS_USER_MANAGEMENT as string;
                  }
                },
                left: {
                  name: 'cancel',
                  children: 'Cancelar',
                  onClick: () => setCanShowRedirectConfirmModal(false)
                }
              }}
            />
          )}
        </div>
      </header>

      <div>
        <hr />
        <span className="subtitle">Leia abaixo as perguntas frequentes</span>

        {frequentlyAskedQuestions.map((fq) => (
          <CollapseItem
            title={fq.question}
            description={fq.answer}
            link={fq?.link}
          />
        ))}
      </div>
    </Container>
  );
};
