import React, { useContext, useState } from 'react';
import { Container } from './styles';
import { Button, Checkbox, Icon, SearchField } from '@gerdau/hefesto';
import { useNavigate } from 'react-router-dom';
import { ERoutes, EOpenTicketRoutes } from '../..';
import AppTable from '../../../../../components/AppTable';
import { OpenTicketContext } from '..';
import { Sequence } from '../../../../../types/business';
import TagImage from '../../../../../components/TagImage';

const ItemsTable: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const { ticketForm, setTicketForm } = useContext(OpenTicketContext);

  const [sequences] = useState(
    ticketForm.deliveryRoll?.romaneios
      .map((romaneio) => romaneio.sequences)
      .flat(1) || []
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedIds = JSON.parse(
      JSON.stringify(ticketForm.sequences)
    ) as string[];
    const selected = event.target.value;
    if (event.target.checked) {
      selectedIds = [...selectedIds, selected];
    } else {
      selectedIds = selectedIds.filter((item) => item !== selected);
    }
    setTicketForm((prev) => ({ ...prev, sequences: selectedIds }));
  };

  function filterSequences(sequence: Sequence): boolean {
    return sequence.number.toString().includes(searchValue);
  }

  return (
    <Container>
      <div className="filter-container">
        <SearchField
          label=""
          hideLabel
          placeholder="Nº da sequência"
          name="search-sequence"
          inputValue={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <AppTable
        columns={[
          { title: '' },
          { title: 'Formato' },
          { title: 'Sequência' },
          { title: 'Quantidade' }
        ]}
        rows={sequences.filter(filterSequences).map((sequence) => ({
          tds: [
            {
              content: (
                <Checkbox
                  name="ticket-item"
                  onChange={handleCheckboxChange}
                  value={sequence.id}
                  id={sequence.id}
                  checked={ticketForm.sequences.includes(sequence.id)}
                />
              )
            },
            { content: <TagImage id={sequence.picture} /> },
            { content: sequence.number },
            { content: sequence.quantity }
          ]
        }))}
      />

      <Button
        name="continue"
        iconPosition="right"
        variant="primary"
        icon={<Icon option="ArrowRight" />}
        disabled={ticketForm.sequences.length < 1}
        onClick={() => {
          navigate(`${ERoutes.OPEN_TICKET}/${EOpenTicketRoutes.FORM}`);
        }}
      >
        Continuar
      </Button>
    </Container>
  );
};

export default ItemsTable;
