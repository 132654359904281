export interface DecodedTag {
  number: string;
  date: string;
}

export const _TAG_REGEX_ =
  /^([0-9]{6})(0[1-9]|10|11|12)(0[1-9]|1[0-9]|2[0-9]|3[0-1])([0-9]{6})$/;

export function barTagStringProcessor(val: string): DecodedTag {
  const result: DecodedTag = {
    number: '',
    date: ''
  };

  result.number = `${Number(val.slice(-5))}`;
  result.date = `20${val.slice(4, 10)}`;

  return result;
}

export function matchesWithTagStringFormat(val: string): boolean {
  return _TAG_REGEX_.test(val);
}
