import React from 'react';
import { Link } from '@gerdau/hefesto';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../../..';
import AppTable from '../../../../../../components/AppTable';
import { Complain, complainStatusMap } from '../../../../../../types/business';
import { formatWeight } from '../../../../../../utils/formatValues';
import { StatusText } from './styles';

interface IComplainsTableProps {
  complains: Complain[];
}

const ComplainsTable: React.FC<IComplainsTableProps> = ({ complains }) => {
  const navigate = useNavigate();
  function redirectToTicket(selectedComplain: Complain) {
    navigate(ERoutes.TICKET, { state: { complain: selectedComplain } });
  }

  return (
    <AppTable
      columns={[
        { title: 'Nº Rol' },
        { title: 'Status' },
        { title: 'Data' },
        { title: 'Peso' }
      ]}
      rows={complains.map((item) => ({
        tds: [
          {
            content: (
              <Link
                name={item.delivery_roll_number.toString()}
                onClick={() => redirectToTicket(item)}
                url="#"
              />
            )
          },
          {
            content: (
              <StatusText>{complainStatusMap.get(item.status)}</StatusText>
            )
          },
          { content: new Date(item.date).toLocaleDateString('pt-BR') },
          { content: formatWeight(item.weight_kg) }
        ]
      }))}
    />
  );
};

export default ComplainsTable;
