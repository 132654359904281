import React, { useContext, useEffect, useState } from 'react';
import ToCheckTable from './ToCheckTable';
import { DeliveryRoll } from '../../../../../types/business';
import CDBConferenceContext from '../../../../../contexts/app';
import { DeliveryRollsContext } from '..';

const ToCheck: React.FC = () => {
  const context = useContext(CDBConferenceContext);
  const { searchValue } = useContext(DeliveryRollsContext);
  const [deliveryRolls, setDeliveryRolls] = useState<DeliveryRoll[]>([]);

  function filterDeliveryRolls(deliveryRoll: DeliveryRoll): boolean {
    const { number, romaneios } = deliveryRoll;
    return (
      number.toString().includes(searchValue) ||
      romaneios.some((ro) =>
        ro.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }

  useEffect(() => {
    const rolls = context.computed.toCheck;
    setDeliveryRolls(rolls);
  }, [context.computed]);
  return <ToCheckTable items={deliveryRolls.filter(filterDeliveryRolls)} />;
};

export default ToCheck;
