import React from 'react';
import { Container } from './styles';
import { SearchField, Title } from '@gerdau/hefesto';
import GoBackButton from '../../../../components/GoBackButton';
import AppTable from '../../../../components/AppTable';

const OrderHistory: React.FC = () => {
  return (
    <Container>
      <header>
        <div>
          <GoBackButton />
          <h2>Histórico do pedido</h2>
        </div>

        <div>
          <Title level={6}>Rol de entrega: 15532</Title>
        </div>

        <div className="input-container">
          <SearchField
            label=""
            hideLabel
            placeholder="Nº da sequência"
            name="search-delivery"
          />
        </div>
      </header>

      <AppTable
        columns={[
          { title: 'Formato' },
          { title: 'Sequência' },
          { title: 'Romaneio' }
        ]}
        rows={[
          {
            tds: [
              { content: 'imagem' },
              { content: '12487' },
              { content: '12532' }
            ]
          },
          {
            tds: [
              { content: 'imagem' },
              { content: '12487' },
              { content: '12532' }
            ]
          },
          {
            tds: [
              { content: 'imagem' },
              { content: '12487' },
              { content: '12532' }
            ]
          }
        ]}
      />
    </Container>
  );
};

export default OrderHistory;
