import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.secondary_color_03_50};
  padding: 1rem 1rem 2.5rem 1rem;
  height: 100%;

  & > header {
    margin-bottom: 1.5rem;

    & > h2 {
      font-size: 1.5rem;
      font-weight: 600;
      font-family: ${({ theme }) => theme.fonts.sourceSansPro};
      color: ${({ theme }) => theme.colors.secondary_color_02_500};
      margin-bottom: 0.25rem;
    }

    & > span {
      color: ${({ theme }) => theme.colors.secondary_color_01_300};
      font-size: 0.875rem;
    }
  }

  & > div.input-container {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;

    & > button {
      padding: 0;
      width: 3rem;
      height: 3rem;

      & .hefesto-icon {
        width: 2.25rem;
        height: 2.25rem;
        color: ${({ theme }) => theme.colors.secondary_color_01_300};
      }
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  width: calc(100% + 2rem);
  position: relative;
  right: 1rem;
  padding-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    width: unset;
    position: unset;
  }
`;
