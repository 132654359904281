import React, { useEffect, useMemo, useRef, useState } from 'react';
import Sdk from '../../sdk';

const TRANSPARENT_GIF_BASE64 = `data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`;

export type TagImageProps = {
  id: string;
  width?: number;
  height?: number;
};
function TagImage({ id, width = 56, height = 56 }: TagImageProps) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [data, setData] = useState(TRANSPARENT_GIF_BASE64);
  const [imgLoaded, setImgLoaded] = useState(false);

  const isInViewport = useIsInViewport(imgRef);

  useEffect(() => {
    if (isInViewport && !imgLoaded) {
      loadImage();
    }
  }, [isInViewport]);

  async function loadImage() {
    const response = await Sdk.tagImage(id);
    setImgLoaded(true);
    setData(response);
  }

  return (
    <img ref={imgRef} src={data} loading="lazy" style={{ height, width }} />
  );
}

function useIsInViewport(ref: React.MutableRefObject<HTMLImageElement | null>) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default TagImage;
