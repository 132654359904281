import React, { createContext, useContext, useState } from 'react';

export enum ENotificationTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger'
}

interface INotification {
  id: React.Key;
  text: string;
  type: ENotificationTypes;
}

interface INotificationsState {
  notifications: INotification[];
}

const NotificationsContext = createContext<
  | {
      notificationsState: INotificationsState;
      setNotificationsState: React.Dispatch<
        React.SetStateAction<INotificationsState>
      >;
    }
  | undefined
>(undefined);

export function NotificationsProvider({
  children
}: {
  children?: React.ReactNode;
}) {
  const [notificationsState, setNotificationsState] =
    useState<INotificationsState>({ notifications: [] });

  return (
    <NotificationsContext.Provider
      value={{ notificationsState, setNotificationsState }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationsContext);
  if (!context) throw new Error('useNotifications failed');
  const { notificationsState, setNotificationsState } = context;
  const { notifications } = notificationsState;

  const addNotification = async (
    text: string,
    type: ENotificationTypes,
    autoClose?: number
  ) => {
    const newNotification: INotification = {
      text,
      type,
      id: (Math.random() * 10).toFixed(6)
    };

    await setNotificationsState({
      notifications: [...notifications, newNotification]
    });

    if (autoClose) {
      setTimeout(() => {
        removeNotification(newNotification);
      }, autoClose);
    }
  };

  const removeNotification = (notificationToRemove: INotification) => {
    setNotificationsState((prev) => ({
      notifications: prev.notifications.filter(
        (n) => n.id !== notificationToRemove.id
      )
    }));
  };

  return { notifications, addNotification, removeNotification };
}
