import React, { useEffect } from 'react';
import Sdk from '../sdk';
import { datadogRum } from '@datadog/browser-rum';
import CDBConferenceContext from '../contexts/app';
import Router from './Router';
import {
  AUTH_TOKEN_STORAGE_KEY,
  GRAPH_TOKEN_STORAGE_KEY
} from '../constants/storage';
import LoaderBox from '../components/LoaderBox';
import LoadingOverlay from '../components/LoadingOverlay';
import { useAppStates } from '../hooks/appStates';
import {
  ENotificationTypes,
  useNotifications
} from '../contexts/notifications';

function EntryPoint() {
  const cdbStates = useAppStates();
  const { addNotification } = useNotifications();

  const {
    initialData,
    computedData,
    currentUser,
    loadingUser,
    loadingOverlay
  } = cdbStates.states;

  const {
    setLoadingOverlay,
    authenticate,
    logout,
    loginAs,
    appendDeliveryRoll,
    appendComplain,
    updateSequenceStatus
  } = cdbStates.methods;

  async function initializeDataDog(): Promise<void> {
    const env = process.env.REACT_APP_BASE_URL;
    if (env === 'prod') {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        env,
        sessionSampleRate: 80
      });
    }
  }

  async function getToken(): Promise<string | null> {
    return new Promise((res) =>
      res(localStorage.getItem(AUTH_TOKEN_STORAGE_KEY))
    );
  }

  async function getGraphToken(): Promise<string | null> {
    const graphTokenStorage = JSON.parse(
      localStorage.getItem(GRAPH_TOKEN_STORAGE_KEY) ?? '{}'
    );

    let token = graphTokenStorage.token as string;
    const now = new Date().getTime();
    if (
      !token ||
      now - graphTokenStorage.time >= graphTokenStorage.expires_in
    ) {
      const { access_token, expires_in } = await Sdk.loadGraphToken();
      token = access_token;
      localStorage.setItem(
        GRAPH_TOKEN_STORAGE_KEY,
        JSON.stringify({ token, expires_in: expires_in * 1000, time: now })
      );
    }

    return new Promise((res) => res(token));
  }

  useEffect(() => {
    async function onRequestRejectedByAuthentication() {
      addNotification(
        'Sessão expirada! Faça o login novamente.',
        ENotificationTypes.WARNING,
        5000
      );
      await logout();
    }
    Sdk.addGetTokenCallback(getToken);
    Sdk.addGetGraphTokenCallback(getGraphToken);
    Sdk.addUnauthorizedCallback(onRequestRejectedByAuthentication);
    initializeDataDog();
  }, [authenticate]);

  return (
    <CDBConferenceContext.Provider
      value={{
        data: initialData,
        computed: computedData,
        auth: {
          logout,
          loginAs,
          user: currentUser
        },
        conference: {
          appendDeliveryRoll,
          appendComplain,
          updateSequenceStatus
        },
        loading: {
          show: () => setLoadingOverlay(true),
          hide: () => setLoadingOverlay(false)
        }
      }}
    >
      {loadingUser && <LoaderBox />}

      {loadingOverlay && <LoadingOverlay />}
      <Router />
    </CDBConferenceContext.Provider>
  );
}

export default EntryPoint;
