import React, { createContext, useEffect, useState } from 'react';
import { Container } from './styles';
import { Title } from '@gerdau/hefesto';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import GoBackButton from '../../../../components/GoBackButton';
import { DeliveryRoll, TicketFormFields } from '../../../../types/business';

export interface TicketFormType extends TicketFormFields {
  currentPage: number;
  deliveryRoll?: DeliveryRoll;
  sequences: string[];
}

export const INITIAL_TICKET_STATE: TicketFormType = {
  sequences: [],
  currentPage: 1,
  q1: '', // Digite o número do contrato
  q2: '', // Informe o número da nota fiscal envolvida
  q3: '', // Deixe um nome para contato
  q4: '', // Telefone para contato
  q5: '', // E-mail para contato
  q6: '', // No que podemos ajudar?
  q7: '', // Onde o problema foi detectado?
  q8: '', // Qual o tipo de problema com o material?
  q9: '', // Descreva o ocorrido (faturamento)
  q10: '', // Qual o tipo de falha de quantidade seu caso melhor se encaixa?
  q11: '', // Informe o número do(s) romaneio(s) envolvido(s)
  q12: '', // Informe o número da(s) sequência(s) envolvida(s)
  q13: '', // Informações adicionais
  q14: '', // Descreva quais materiais (desenhos, elementos estruturais ou posições) deveriam constar...
  q15: '', // Informe o número do(s) romaneio(s) envolvido(s)
  q16: '', // Informe o número da(s) sequência(s) envolvida(s)
  q17: '' // Informações adicionais
};

export interface IOpenTicketLocationState {
  deliveryRoll: DeliveryRoll;
  sequences?: string[];
}

export interface OpenTicketContextType {
  ticketForm: TicketFormType;
  setTicketForm: React.Dispatch<React.SetStateAction<TicketFormType>>;
}

export const OpenTicketContext = createContext({} as OpenTicketContextType);

const OpenTicket = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state as IOpenTicketLocationState;
  const [ticketForm, setTicketForm] = useState(INITIAL_TICKET_STATE);

  useEffect(() => {
    if (locationState.deliveryRoll) {
      setTicketForm((prev) => ({
        ...prev,
        ...locationState
      }));
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Container>
      <header>
        <div>
          <GoBackButton />
          <h2>Abrir manifestação</h2>
        </div>

        <div>
          <Title level={6}>
            {`Rol de entrega: ${ticketForm.deliveryRoll?.number || ''}`}
          </Title>
        </div>
      </header>

      <OpenTicketContext.Provider value={{ ticketForm, setTicketForm }}>
        {ticketForm.deliveryRoll && <Outlet />}
      </OpenTicketContext.Provider>
    </Container>
  );
};

export default OpenTicket;
