import styled from 'styled-components';

export const AppHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--primary-color-01-300);
  box-shadow: var(--shadow-snackbar);
  display: flex;
  justify-content: center;

  & > .hefesto-appbar {
    max-width: ${({ theme }) => theme.breakpoints.laptopL};
    position: unset;
    box-shadow: unset;
  }
`;

export const HeaderTitle = styled.span`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
`;
