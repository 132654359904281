import { useState } from 'react';
import { ComputedData, IUser } from '../../contexts/app';
import {
  ENotificationTypes,
  useNotifications
} from '../../contexts/notifications';
import { InitialDataResponse } from '../../types/communication';
import {
  Complain,
  DeliveryRoll,
  Sequence,
  SequenceStatus
} from '../../types/business';
import {
  AUTH_TOKEN_STORAGE_KEY,
  GRAPH_TOKEN_STORAGE_KEY
} from '../../constants/storage';
import Sdk from '../../sdk';

function deepClone<T extends object>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
function onlyDeliveryRollsChecked(
  deliveryRolls: DeliveryRoll[]
): DeliveryRoll[] {
  return deliveryRolls.filter(
    (dr) =>
      !dr.romaneios.some((r) =>
        r.sequences.some((seq) => seq.status === SequenceStatus.NotVerified)
      )
  );
}
function onlyDeliveryRollsAndManifestsWithSequencesOfStatus(
  deliveryRolls: DeliveryRoll[],
  status: SequenceStatus
): DeliveryRoll[] {
  return deliveryRolls.filter((deliveryRoll) => {
    return deliveryRoll.romaneios.some((manifest) => {
      return manifest.sequences.some((sequence) => sequence.status === status);
    });
  });
}

function computeData(initial: InitialDataResponse): ComputedData {
  const clonedDeliveryRolls = deepClone<DeliveryRoll[]>(initial.delivery_rolls);
  const result: ComputedData = {
    complains: initial.complains,
    checked: onlyDeliveryRollsChecked(clonedDeliveryRolls),
    toCheck: onlyDeliveryRollsAndManifestsWithSequencesOfStatus(
      clonedDeliveryRolls,
      SequenceStatus.NotVerified
    ),
    profile: initial.profile
  };

  return result;
}

export function useAppStates() {
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const { addNotification } = useNotifications();

  const [initialData, setInitialData] = useState<InitialDataResponse>({
    success: true,
    complains: [],
    delivery_rolls: [],
    profile: {
      email: '',
      name: ''
    }
  });

  const [computedData, setComputedData] = useState<ComputedData>({
    toCheck: [],
    checked: [],
    complains: [],
    profile: {
      email: '',
      name: ''
    }
  });

  async function loadInitialData() {
    try {
      const response = await Sdk.loadInitialData();
      await updateData(response);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('error loading initial data', error);
      if (error.response?.status !== 401) {
        addNotification(
          'Falha ao carregar os dados.',
          ENotificationTypes.DANGER
        );
      }
    }
  }

  async function authenticate(user: IUser | undefined) {
    setLoadingUser(true);
    await setCurrentUser(user);

    if (user) {
      await loadInitialData();
    }
    setLoadingUser(false);
  }

  async function updateData(_initial: InitialDataResponse): Promise<void> {
    await setInitialData(_initial);
    await setComputedData(computeData(_initial));
  }

  async function logout(): Promise<void> {
    await localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
    await localStorage.removeItem(GRAPH_TOKEN_STORAGE_KEY);
    await authenticate(undefined);
  }

  async function loginAs(user: IUser | undefined): Promise<void> {
    await authenticate(user);
  }

  function appendDeliveryRoll(deliverRoll: DeliveryRoll): void {
    const clone = deepClone(initialData);
    clone.delivery_rolls.push(deliverRoll);
    updateData(clone);
  }

  function appendComplain(complain: Complain) {
    const clone = deepClone(initialData);
    clone.complains.push(complain);
    updateData(clone);
  }

  function updateSequenceStatus(
    sequence: Sequence,
    deliveryRollId: string,
    romaneioId: string
  ) {
    const clone = deepClone(initialData);
    const deliveryRoll = clone.delivery_rolls.find(
      (dr) => dr.id === deliveryRollId
    );
    if (deliveryRoll) {
      const romaneio = deliveryRoll.romaneios.find((r) => r.id === romaneioId);
      if (romaneio) {
        const sequenceIndex = romaneio.sequences.findIndex(
          (seq) => seq.id === sequence.id
        );
        if (sequenceIndex > -1) {
          romaneio.sequences[sequenceIndex] = sequence;
        }
      }
    }
    updateData(clone);
  }

  return {
    states: {
      initialData,
      computedData,
      currentUser,
      loadingUser,
      loadingOverlay
    },
    methods: {
      setLoadingUser,
      setCurrentUser,
      setInitialData,
      setComputedData,
      setLoadingOverlay,
      authenticate,
      loadInitialData,
      updateData,
      logout,
      loginAs,
      appendDeliveryRoll,
      appendComplain,
      updateSequenceStatus
    }
  };
}
