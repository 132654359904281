import React from 'react';
import Login from './Login';
import { Routes, Route, Navigate } from 'react-router-dom';

export function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path={'/login'} element={<Login />}></Route>
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Routes>
  );
}

export default UnauthenticatedRoutes;
