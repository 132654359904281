import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.secondary_color_03_50};
  box-shadow: 0px 7px 24.49px -5.25px rgba(24, 39, 75, 0.12),
    0px 15.75px 76.98px -3.5px rgba(24, 39, 75, 0.14);
  border-radius: 0px 0px 4px 4px;

  & > ul {
    list-style-type: none;

    & > li {
      background: ${({ theme }) => theme.colors.secondary_color_03_50};
      padding: 0.75rem 1rem;
      line-height: 1.5rem;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      color: ${({ theme }) => theme.colors.secondary_color_01_300};

      &:hover {
        filter: brightness(0.95);
      }
    }
  }
`;
