import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  & > table {
    width: 100%;
    border-collapse: collapse;
    background: ${({ theme }) => theme.colors.secondary_color_03_50};

    & th,
    & td {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.secondary_color_04_400};
      padding-left: 0.25rem;

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }
    }
  }
`;

export const THead = styled.thead`
  height: 2.625rem;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primary_color_01_800};
  text-align: left;

  & > tr {
    & > th {
      font-weight: 400;
    }
  }
`;

export const TBody = styled.tbody`
  color: ${({ theme }) => theme.colors.primary_color_01_800};
  background: ${({ theme }) => theme.colors.secondary_color_03_50};

  & > tr {
    height: 3.625rem;

    &.expanded {
      & ~ tr {
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.secondary_color_04_400};
      }
      td {
        border-bottom: none;
      }
    }
    & > td {
      font-weight: 400;

      & > div {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }
  }
`;
