import React, { useState } from 'react';
import { MenuContainer } from './styles';
import RCTrigger from '@rc-component/trigger';
import { Icon } from '@gerdau/hefesto';
import Overlay from './Overlay';

interface IOptionsMenu {
  children: JSX.Element;
  items: {
    title: string;
    onClick: () => void;
  }[];
}

const OptionsMenu: React.FC<IOptionsMenu> = ({ children, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && <Overlay />}
      <RCTrigger
        action={['click']}
        popupVisible={isOpen}
        onPopupVisibleChange={() => setIsOpen(!isOpen)}
        popup={
          <MenuContainer>
            <ul>
              {items.map((item) => (
                <li
                  key={item.title}
                  onClick={() => {
                    item.onClick();
                    setIsOpen(false);
                  }}
                >
                  <span>{item.title}</span>
                  <Icon option="CaretRight" size="sm" variant="tertiary" />
                </li>
              ))}
            </ul>
          </MenuContainer>
        }
        popupAlign={{
          points: ['cr', 'cl'],
          offset: [0, 3]
        }}
      >
        {children}
      </RCTrigger>
    </>
  );
};

export default OptionsMenu;
