import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.primary_color_01_300};
  flex-direction: column;
  align-items: center;

  & > span {
    color: ${({ theme }) => theme.colors.secondary_color_03_50};
  }

  & > form {
    width: 100%;
    max-width: ${({ theme }) => theme.breakpoints.tablet};

    & > div {
      margin-bottom: 1rem;

      &.actions {
        width: 100%;
        margin-top: 2.875rem;

        & > span {
          color: ${({ theme }) => theme.colors.secondary_color_03_50};
          font-size: 1rem;
          line-height: 1.5rem;
          font-family: ${(props) => props.theme.fonts.workSans};
          font-weight: 600;
        }

        & > button {
          width: 100%;
        }
      }
    }
  }
`;

export const UseTermsContainer = styled.div`
  width: 100%;
  max-width: 768px;

  & > span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.secondary_color_03_50};
  }

  & > a {
    color: ${({ theme }) => theme.colors.secondary_color_03_50};
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: baseline;
  vertical-align: middle;
  position: absolute;
  bottom: 10px;
  gap: 5px;
  color: #fff;

  & > span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.secondary_color_03_50};
  }

  & > a {
    color: ${({ theme }) => theme.colors.secondary_color_03_50};
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`;
