import React, { useContext, useEffect, useState } from 'react';
import ComplainsTable from './ComplainsTable';
import CDBConferenceContext from '../../../../../contexts/app';
import { Complain } from '../../../../../types/business';
import { DeliveryRollsContext } from '..';

const Complains: React.FC = () => {
  const context = useContext(CDBConferenceContext);
  const { searchValue } = useContext(DeliveryRollsContext);

  const [complains, setComplains] = useState<Complain[]>([]);

  function filterComplains(complain: Complain): boolean {
    const { delivery_roll_number, sequences } = complain;
    return (
      delivery_roll_number.toString().includes(searchValue) ||
      sequences.some(
        (seq) =>
          seq.romaneio.toLowerCase().includes(searchValue.toLowerCase()) ||
          seq.number.toString().includes(searchValue)
      )
    );
  }

  useEffect(() => {
    setComplains(context.computed.complains);
  }, [context.computed]);

  return <ComplainsTable complains={complains.filter(filterComplains)} />;
};

export default Complains;
