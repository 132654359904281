import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;

  background-color: ${({ theme }) => theme.colors.secondary_color_05_500};
`;

export const Content = styled.div`
  display: flex;
  align-self: center;
  height: 3.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1.75rem;
  width: 23.5rem;
  margin: 0 auto;

  & > div {
    width: 40%;
    height: 100%;
    display: flex;
    align-self: center;
    justify-content: center;

    & > button[name='home'] {
      margin-right: 2.25rem;
    }

    & > button[name='settings'] {
      margin-right: 2rem;
    }
  }

  & .hefesto-button {
    padding: 0.125rem;
    height: unset;
    &__label {
      font-size: 0.75rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${({ theme }) => theme.colors.secondary_color_01_300};

      & .hefesto-icon {
        color: ${({ theme }) => theme.colors.primary_color_02_600};
      }
    }
  }
`;

export const ReaderButtonContainer = styled.span`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  justify-self: center;
  margin: auto;
  & > button.hefesto-button {
    background: ${({ theme }) => theme.colors.primary_color_02_600};
    border: 0.25rem solid ${({ theme }) => theme.colors.background};
    min-width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    padding: 0.35rem;

    & .hefesto-icon {
      width: 2.25rem;
      height: 2.25rem;
    }
  }
`;
