import React from 'react';
import { Button, Icon } from '@gerdau/hefesto';
import { useNavigate } from 'react-router-dom';

interface IGoBackButtonProps {
  onClick?: () => void;
  title?: string;
}

const GoBackButton: React.FC<IGoBackButtonProps> = ({ onClick, title }) => {
  const navigate = useNavigate();

  const handleDefaulClick = () => {
    navigate(-1);
  };

  return (
    <Button
      name="go-back-btn"
      variant="tertiary"
      onClick={onClick || handleDefaulClick}
      icon={<Icon option="ArrowLeft" size="md" />}
    >
      {title}
    </Button>
  );
};

export default GoBackButton;
