import React, { useState } from 'react';
import { Button, Icon, Title } from '@gerdau/hefesto';
import { CollapseWithLink, Container } from './styles';

interface CollapseItemProps {
  title: string;
  description: string;
  link?: string;
}

function CollapseItem(props: CollapseItemProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  function collapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <Container>
      <div onClick={collapse}>
        <Title level={6}>{props.title}</Title>
        <Button
          variant="neutral"
          icon={
            <Icon
              variant="primary"
              option={isCollapsed ? 'ChevronUp' : 'ChevronDown'}
            />
          }
          name="collapse-btn"
        />
      </div>

      {isCollapsed &&
        (!props.link ? (
          <span className="description">{props.description}</span>
        ) : (
          <CollapseWithLink>
            <span className="description">{props.description}</span>
            <a href={props.link} target="_blank" rel="noreferrer">
              {props.link}
            </a>
          </CollapseWithLink>
        ))}
    </Container>
  );
}

export default CollapseItem;
