import React from 'react';
import { TicketFormProps } from '..';
import ActionsFooter from '../ActionsFooter';
import { FieldsContainer } from '../styles';
import { Select, TextArea, TextField } from '@gerdau/hefesto';

function TicketPage3({
  ticketForm,
  setTicketForm,
  onFinish,
  loading
}: TicketFormProps) {
  const isAQuantityIssue =
    ticketForm.q8 !== '' && ticketForm.q8.includes('Quantidade');

  function disabledNextButton(): boolean {
    const { q10, q11, q12 } = ticketForm;
    if (isAQuantityIssue) {
      return q10 === '';
    } else {
      return q11 === '' || q12 === '';
    }
  }
  return (
    <>
      <FieldsContainer>
        <h5>{`Problemas com o material - ${
          isAQuantityIssue ? 'Quantidade' : 'Detalhes'
        }`}</h5>
        {isAQuantityIssue && (
          <Select
            name="q10"
            value={ticketForm.q10}
            disabled={loading}
            onSelect={(selected) =>
              setTicketForm((prev) => ({
                ...prev,
                q10: selected as string,
                q11: '',
                q12: '',
                q13: '',
                q14: '',
                q15: '',
                q16: '',
                q17: ''
              }))
            }
            label="Qual o tipo de falha de quantidade?"
            options={[
              'Falta total (etiqueta que consta no romaneio não foi encontrada)',
              'Falta parcial (quantidade da etiqueta não confere com o recebido)',
              'Divergência entre pedido e romaneio (no romaneio está diferente do projeto)',
              'Material não consta no romaneio (algo que foi solicitado no pedido não está no romaneio)',
              'Outra'
            ].map((item) => ({ value: item, label: item }))}
          />
        )}

        {!isAQuantityIssue && (
          <>
            <TextField
              name="Q11"
              label="Romaneio(s) envolvido(s)"
              placeholder="Insira os romaneio(s) envolvido(s)"
              value={ticketForm.q11}
              disabled={loading}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q11: e.target.value }))
              }
            />
            <TextField
              name="q12"
              label="Sequência(s) envolvida(s)"
              placeholder="Insira a(s) sequência(s) envolvida(s)"
              value={ticketForm.q12}
              disabled={loading}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q12: e.target.value }))
              }
            />
            <TextArea
              name="q13"
              label="Informações adicionais"
              placeholder="Insira sua resposta"
              value={ticketForm.q13}
              disabled={loading}
              cols={300}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q13: e.target.value }))
              }
            />
          </>
        )}
      </FieldsContainer>

      <ActionsFooter
        ticketForm={ticketForm}
        setTicketForm={setTicketForm}
        disabledBackButton={loading}
        disabledNextButton={loading || disabledNextButton()}
        lastPage={!isAQuantityIssue}
        onFinish={onFinish}
      />
    </>
  );
}

export default TicketPage3;
