import React, { useContext, useState } from 'react';
import { FormContainer } from './styles';
import TicketPage1 from './TicketPage1';
import TicketPage2 from './TicketPage2';
import TicketPage3 from './TicketPage3';
import TicketPage4 from './TicketPage4';
import { OpenTicketContext, TicketFormType } from '..';
import { TicketFormFields } from '../../../../../types/business';
import Sdk from '../../../../../sdk';
import CDBConferenceContext from '../../../../../contexts/app';
import {
  ENotificationTypes,
  useNotifications
} from '../../../../../contexts/notifications';
import { useNavigate } from 'react-router-dom';
import { EDeliveryRollsRoutes, ERoutes } from '../..';

export type OnFinishFormCallback = () => void | Promise<void>;
export interface TicketFormProps {
  ticketForm: TicketFormType;
  setTicketForm: React.Dispatch<React.SetStateAction<TicketFormType>>;

  loading?: boolean;
  onFinish: OnFinishFormCallback;
}

const TicketForm: React.FC = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const context = useContext(CDBConferenceContext);

  const [loading, setLoading] = useState(false);

  const { ticketForm, setTicketForm } = useContext(OpenTicketContext);

  async function onFinishedForm() {
    if (!ticketForm.deliveryRoll) {
      return;
    }

    const clone = JSON.parse(JSON.stringify(ticketForm));
    delete clone.currentPage;
    delete clone.deliveryRoll;
    const ticket: TicketFormFields = clone as TicketFormFields;

    try {
      setLoading(true);
      context.loading.show();
      const response = await Sdk.complain(
        ticketForm.deliveryRoll.id,
        ticketForm.sequences,
        ticket
      );
      context.conference.appendComplain(response.newComplain);
      addNotification(
        'Manifestação aberta com sucesso',
        ENotificationTypes.SUCCESS,
        5000
      );
      navigate(`${ERoutes.DELIVERY_ROLLS}/${EDeliveryRollsRoutes.COMPLAINS}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error({ error });
      if (error.response?.status !== 401) {
        addNotification(
          'Erro ao abrir a manifestação',
          ENotificationTypes.DANGER
        );
      }
    } finally {
      setLoading(false);
      context.loading.hide();
    }
  }

  return (
    <FormContainer>
      <h5>Insira as informações abaixo</h5>

      {ticketForm.currentPage === 1 && (
        <TicketPage1
          onFinish={onFinishedForm}
          ticketForm={ticketForm}
          setTicketForm={setTicketForm}
          loading={loading}
        />
      )}

      {ticketForm.currentPage === 2 && (
        <TicketPage2
          onFinish={onFinishedForm}
          ticketForm={ticketForm}
          setTicketForm={setTicketForm}
          loading={loading}
        />
      )}

      {ticketForm.currentPage === 3 && (
        <TicketPage3
          onFinish={onFinishedForm}
          ticketForm={ticketForm}
          setTicketForm={setTicketForm}
          loading={loading}
        />
      )}

      {ticketForm.currentPage === 4 && (
        <TicketPage4
          onFinish={onFinishedForm}
          ticketForm={ticketForm}
          setTicketForm={setTicketForm}
          loading={loading}
        />
      )}
    </FormContainer>
  );
};

export default TicketForm;
