import React from 'react';
import { Button } from '@gerdau/hefesto';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const OverlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.overlay};
  left: 0;
  top: 0;

  & button.hefesto-button--tertiary:hover {
    background-color: transparent;
  }
`;

function LoadingOverlay() {
  return createPortal(
    <OverlayContainer>
      <Button name="loading" variant="tertiary" loading></Button>
    </OverlayContainer>,
    document.body
  );
}

export default LoadingOverlay;
