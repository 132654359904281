import { DeliveryRoll, SequenceStatus, Sequence } from '../../types/business';

export function extractAllSequencesWithStatus(
  deliveryRoll: DeliveryRoll,
  status: SequenceStatus
): Sequence[] {
  return deliveryRoll.romaneios
    .map((romaneio) => romaneio.sequences)
    .flat(1)
    .filter((seq) => seq.status === status);
}
