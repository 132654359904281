import axios from 'axios';

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_SECRET
  }
});

const AxiosGraphTokenInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH_SECRET
  }
});

export const HTTP_CLIENT = AxiosInstance;
export const HTTP_AUTH = AxiosGraphTokenInstance;
