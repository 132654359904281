import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CDBConferenceContext from '../../contexts/app';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';

function Router() {
  const context = useContext(CDBConferenceContext);
  return (
    <BrowserRouter>
      {context.auth.user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
    </BrowserRouter>
  );
}

export default Router;
