import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  gap: 1rem;

  & > button {
    width: 100%;
  }
`;
