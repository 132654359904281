import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  gap: 1.5rem;

  & > .filter-container {
    padding: 0 1rem;
  }

  & > button {
    position: fixed;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: ${({ theme }) => theme.breakpoints.tablet};
  }
`;
