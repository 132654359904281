export interface SequenceDimension {
  value: number;
  letter: string;
}
export interface Sequence {
  id: string;
  quantity: number;
  picture: string;
  status: SequenceStatus;
  received_date: string;
  weight_kg: number;
  number: number;
  diameter: number | string;
  dimensions: SequenceDimension[];
}

export enum SequenceStatus {
  NotVerified = 'notVerified',
  Checked = 'checked',
  Missing = 'missing'
}
export interface Manifest {
  id: string;
  name: string;
  weight_kg: number;
  sequences: Sequence[];
}
export interface DeliveryRoll {
  id: string;
  number: number;
  weight_kg: string;
  romaneios: Manifest[];
}

export interface TicketFormFields {
  q1: string;
  q2: string;
  q3: string;
  q4: string;
  q5: string;
  q6: string;
  q7: string;
  q8: string;
  q9: string;
  q10: string;
  q11: string;
  q12: string;
  q13: string;
  q14: string;
  q15: string;
  q16: string;
  q17: string;
}

export enum ComplainStatus {
  Open = 'open',
  Finished = 'finished'
}

export const complainStatusMap = new Map<ComplainStatus, string>();
complainStatusMap.set(ComplainStatus.Open, 'Aberto');
complainStatusMap.set(ComplainStatus.Finished, 'Finalizado');

export interface ComplainSequence {
  romaneio: string;
  number: number;
  picture: string;
  weight_kg: number;
}
export interface Complain {
  id: string;
  delivery_roll_number: number;
  status: ComplainStatus;
  date: string;
  weight_kg: number;
  sequences: ComplainSequence[];
}
