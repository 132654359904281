import React, { useContext, useState } from 'react';
import { Container } from './styles';
import { DeliveryConferenceCard } from '../components/DeliveryConferenceCard/styles';
import { Button, Checkbox } from '@gerdau/hefesto';
import {
  DeliveryRoll,
  Sequence,
  SequenceStatus
} from '../../../../../types/business';
import TagImage from '../../../../../components/TagImage';
import Sdk from '../../../../../sdk';
import {
  ENotificationTypes,
  useNotifications
} from '../../../../../contexts/notifications';
import CDBConferenceContext from '../../../../../contexts/app';
import AppTable from '../../../../../components/AppTable';
import { useNavigate } from 'react-router-dom';
import { EOpenTicketRoutes, ERoutes } from '../..';
import { IOpenTicketLocationState } from '../../OpenTicket';

interface INotFoundProps {
  deliveryRoll: DeliveryRoll;
  sequences: Sequence[];
  searchValue: string;
}

const NotFound: React.FC<INotFoundProps> = ({
  deliveryRoll,
  sequences,
  searchValue
}) => {
  const navigate = useNavigate();
  const [isInTicketView, setIsInTicketView] = useState(false);

  const [selectedSequences, setSelectedSequences] = useState<string[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedIds = JSON.parse(JSON.stringify(selectedSequences)) as string[];
    const selected = event.target.value;
    if (event.target.checked) {
      selectedIds = [...selectedIds, selected];
    } else {
      selectedIds = selectedIds.filter((item) => item !== selected);
    }
    setSelectedSequences(selectedIds);
  };

  const goToOpenTicketPage = () => {
    navigate(`${ERoutes.OPEN_TICKET}/${EOpenTicketRoutes.FORM}`, {
      state: {
        deliveryRoll,
        sequences: selectedSequences
      } as IOpenTicketLocationState
    });
  };

  function filterSequences(sequence: Sequence): boolean {
    return sequence.number.toString().includes(searchValue);
  }

  return (
    <Container className={isInTicketView ? 'is-in-ticket-view' : ''}>
      {!isInTicketView && (
        <>
          {sequences.length > 0 ? (
            sequences
              .filter(filterSequences)
              .map((sequence) => (
                <NotFoundCard key={sequence.id} sequence={sequence} />
              ))
          ) : (
            <>Inicie conferindo uma etiqueta de suas entregas</>
          )}
        </>
      )}

      {isInTicketView && (
        <AppTable
          columns={[
            { title: '' },
            { title: 'Formato' },
            { title: 'Sequência' },
            { title: 'Quantidade' }
          ]}
          rows={sequences.filter(filterSequences).map((sequence) => ({
            tds: [
              {
                content: (
                  <Checkbox
                    name="ticket-item"
                    onChange={handleCheckboxChange}
                    value={sequence.id}
                    id={sequence.id}
                    checked={selectedSequences.includes(sequence.id)}
                  />
                )
              },
              { content: <TagImage id={sequence.picture} /> },
              { content: sequence.number },
              { content: sequence.quantity }
            ]
          }))}
        />
      )}

      {sequences.length > 0 && (
        <div className="actions">
          {!isInTicketView && (
            <Button name="open-ticket" onClick={() => setIsInTicketView(true)}>
              Abrir manifestação
            </Button>
          )}
          {isInTicketView && (
            <>
              <Button
                name="open-ticket"
                variant="danger"
                onClick={() => {
                  setIsInTicketView(false);
                  setSelectedSequences([]);
                }}
              >
                Cancelar
              </Button>
              <Button
                name="open-ticket"
                disabled={selectedSequences.length === 0}
                onClick={goToOpenTicketPage}
              >
                Continuar
              </Button>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

interface NotFoundCardProps {
  sequence: Sequence;
}

const NotFoundCard = ({ sequence }: NotFoundCardProps) => {
  const context = useContext(CDBConferenceContext);
  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState(false);

  async function markAsFound(): Promise<void> {
    try {
      setLoading(true);
      context.loading.show();
      const response = await Sdk.changeTagStatus(
        sequence,
        SequenceStatus.Checked
      );
      if (response.success) {
        context.conference.updateSequenceStatus(
          response.modified_sequence.sequence,
          response.modified_sequence.delivery_roll_id,
          response.modified_sequence.romaneio_id
        );
        switch (response.modified_sequence.sequence.status) {
          case SequenceStatus.Checked:
            addNotification(
              'Item encontrado com sucesso',
              ENotificationTypes.SUCCESS,
              3000
            );
            break;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error({ error });
      if (error.response?.status !== 401) {
        addNotification('Erro ao alterar o status', ENotificationTypes.DANGER);
      }
    } finally {
      setLoading(false);
      context.loading.hide();
    }
  }
  return (
    <DeliveryConferenceCard key={sequence.number}>
      <div>
        <div>
          <span>
            Sequência: <b>{sequence.number}</b>
          </span>
          <span>
            Quantidade: <b>{sequence.quantity}</b>
          </span>
        </div>

        <div>
          <TagImage id={sequence.picture} />
        </div>
      </div>
      <div className="actions">
        <Button
          name="found-btn"
          variant="secondary"
          loading={loading}
          disabled={loading}
          onClick={markAsFound}
        >
          Encontrado
        </Button>
      </div>
    </DeliveryConferenceCard>
  );
};

export default NotFound;
