import {
  ComplainResponse,
  ConferenceResponse,
  InitialDataResponse,
  LoginResponse,
  ProfileResponse,
  UpdateSequenceStatusResponse
} from '../types/communication';
import { HTTP_AUTH, HTTP_CLIENT } from './http';
import { DecodedTag } from '../types/tag';
import { Sequence, SequenceStatus, TicketFormFields } from '../types/business';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

class CDBConferenceSDK {
  public constructor() {
    this.bindHTTPInterceptors();
  }

  public async loadProfile(): Promise<ProfileResponse> {
    return Promise.resolve({
      success: true
    });
  }
  private _getTokenCallback: () => Promise<string | null> = () =>
    Promise.resolve('');
  private _getGraphTokenCallback: () => Promise<string | null> = () =>
    Promise.resolve('');
  private _unAuthorizedCallback: () => void = () => undefined;
  public addUnauthorizedCallback(callback: () => void): void {
    this._unAuthorizedCallback = callback;
  }

  public addGetTokenCallback(callback: () => Promise<string | null>): void {
    this._getTokenCallback = callback;
  }

  public addGetGraphTokenCallback(
    callback: () => Promise<string | null>
  ): void {
    this._getGraphTokenCallback = callback;
  }

  private loadedImagePromisesHash: { [key: string]: Promise<string> } = {};
  public async tagImage(id: string): Promise<string> {
    if (!(id in this.loadedImagePromisesHash)) {
      // eslint-disable-next-line no-async-promise-executor
      this.loadedImagePromisesHash[id] = new Promise(async (res) => {
        try {
          const response = await HTTP_CLIENT.get(`/tag-image/${id}`, {
            responseType: 'arraybuffer'
          });
          res(
            'data:image/gif;base64,' +
              Buffer.from(response.data, 'binary').toString('base64')
          );
        } catch (error) {
          const TRANSPARENT_GIF_BASE64 = `data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`;
          res(TRANSPARENT_GIF_BASE64);
        }
      });
    }
    return this.loadedImagePromisesHash[id];
  }
  public async loadInitialData(): Promise<InitialDataResponse> {
    const response = await HTTP_CLIENT.get('/initial');
    return response.data;
  }

  public async changeTagStatus(
    sequence: Sequence,
    status: SequenceStatus
  ): Promise<UpdateSequenceStatusResponse> {
    const response = await HTTP_CLIENT.put(`/tag/${sequence.id}/status`, {
      status
    });
    return response.data;
  }
  public async check(decodedTag: DecodedTag): Promise<ConferenceResponse> {
    try {
      const response = await HTTP_CLIENT.post('/conference', {
        tags: [decodedTag]
      });
      return response.data;
    } catch (e) {
      return {
        success: false,
        error: 'houve um erro',
        changed: {
          sequences: [],
          new_delivery_roll: {}
        },
        failedSequences: [],
        rejectedSequences: []
      };
    }
  }

  private _loadGraphTokenPromise: Promise<{
    access_token: string;
    expires_in: number;
  }> | null = null;

  public async loadGraphToken(): Promise<{
    access_token: string;
    expires_in: number;
  }> {
    if (!this._loadGraphTokenPromise) {
      this._loadGraphTokenPromise = new Promise((resolve, reject) => {
        HTTP_AUTH.post('/token')
          .then((response) => resolve(response.data))
          .catch((error) => {
            console.error({ error });
            reject('Fail in load graph token');
          })
          .finally(() => (this._loadGraphTokenPromise = null));
      });
    }
    return this._loadGraphTokenPromise;
  }

  public async login(
    userName: string,
    password: string
  ): Promise<LoginResponse> {
    const response = await HTTP_CLIENT.post('/login', {
      username: userName,
      password: password
    });
    return response.data;
  }

  public async complain(
    idDeliveryRoll: string,
    relatedItems: string[],
    answers: TicketFormFields
  ): Promise<ComplainResponse> {
    const response = await HTTP_CLIENT.post('/complain', {
      idDeliveryRoll,
      relatedItems,
      answers
    });
    return response.data;
  }

  private bindHTTPInterceptors(): void {
    HTTP_CLIENT.interceptors.request.use(async (request) => {
      const graphToken = await this._getGraphTokenCallback();
      const token = await this._getTokenCallback();
      request.headers.set(
        'Authorization',
        `Bearer ${graphToken?.replace(/"/g, '')}`
      );
      request.headers.set('Authorization_User', `${token?.replace(/"/g, '')}`);
      request.headers.set('Content-Type', 'application/json');
      return request;
    });
    HTTP_CLIENT.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        const { response } = err;
        if (
          response?.status === 401 ||
          response?.data?.message?.includes('Unauthorized')
        ) {
          this._unAuthorizedCallback();
        }
        return Promise.reject(err);
      }
    );
  }
}

export default new CDBConferenceSDK();
