import React from 'react';
import { TicketFormProps } from '..';
import { Select, TextField } from '@gerdau/hefesto';
import {
  isValidEmail,
  isValidPhoneNumber
} from '../../../../../../utils/inputValidator';
import { FieldsContainer } from '../styles';
import ActionsFooter from '../ActionsFooter';

function TicketPage1({ ticketForm, setTicketForm }: TicketFormProps) {
  const phoneInputValidator = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');

    e.currentTarget.maxLength = 15;
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    setTicketForm((prev) => ({ ...prev, q4: value }));
  };

  return (
    <>
      <FieldsContainer>
        <h5 style={{ padding: 0 }}>Informações da entrega e contato</h5>
        <TextField
          name="q1"
          label="Número do contrato"
          placeholder="Insira o número do contrato"
          value={ticketForm.q1}
          onChange={(e) =>
            setTicketForm((prev) => ({ ...prev, q1: e.target.value }))
          }
        />
        <TextField
          name="q2"
          label="Nota fiscal envolvida"
          placeholder="Informe o número da nota fiscal"
          value={ticketForm.q2}
          onChange={(e) =>
            setTicketForm((prev) => ({ ...prev, q2: e.target.value }))
          }
        />
        <TextField
          name="q3"
          label="Nome para contato"
          placeholder="Insira o nome para contato"
          value={ticketForm.q3}
          onChange={(e) =>
            setTicketForm((prev) => ({ ...prev, q3: e.target.value }))
          }
        />
        <TextField
          name="phone"
          label="Telefone para contato"
          placeholder="(00) 00000-0000"
          value={ticketForm.q4}
          onChange={phoneInputValidator}
          variant={
            ticketForm.q4 === '' || isValidPhoneNumber(ticketForm.q4)
              ? undefined
              : 'danger'
          }
        />
        <TextField
          name="email"
          label="E-mail para contato"
          placeholder="meuemail@email.com"
          value={ticketForm.q5}
          onChange={(e) =>
            setTicketForm((prev) => ({ ...prev, q5: e.target.value }))
          }
          variant={
            ticketForm.q5 === '' || isValidEmail(ticketForm.q5)
              ? undefined
              : 'danger'
          }
        />
      </FieldsContainer>

      <FieldsContainer>
        <h5>Detalhamento sobre o problema</h5>
        <Select
          name="q6"
          label="No que podemos ajudar?"
          value={ticketForm.q6}
          onSelect={(selected) =>
            setTicketForm((prev) => ({
              ...prev,
              q6: selected as string,
              q7: '',
              q8: '',
              q9: '',
              q10: '',
              q11: '',
              q12: '',
              q13: '',
              q14: '',
              q15: '',
              q16: '',
              q17: ''
            }))
          }
          options={[
            'Problema com a nota fiscal (preço, condição de pagamento, etc.)',
            'Problema com o produto'
          ].map((item) => ({ value: item, label: item }))}
        />
      </FieldsContainer>

      <ActionsFooter
        ticketForm={ticketForm}
        setTicketForm={setTicketForm}
        hideBackButton
        onFinish={() => undefined}
        disabledNextButton={
          ticketForm.q1 === '' ||
          ticketForm.q2 === '' ||
          ticketForm.q3 === '' ||
          ticketForm.q4 === '' ||
          !isValidPhoneNumber(ticketForm.q4) ||
          ticketForm.q5 === '' ||
          !isValidEmail(ticketForm.q5) ||
          ticketForm.q6 === ''
        }
      />
    </>
  );
}

export default TicketPage1;
