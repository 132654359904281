import React from 'react';
import { Button, Icon } from '@gerdau/hefesto';
import { useNavigate } from 'react-router-dom';
import ExpandedTableContent from '../../components/ExpandedTableContent';
import { ERoutes } from '../../..';
import AppTable from '../../../../../../components/AppTable';
import { formatWeight } from '../../../../../../utils/formatValues';
import { DeliveryRoll } from '../../../../../../types/business';

interface IToCheckTableProps {
  items: DeliveryRoll[];
}

const ToCheckTable: React.FC<IToCheckTableProps> = ({ items }) => {
  const navigate = useNavigate();
  const onCheckClick = (clicked: DeliveryRoll) => {
    console.warn({ clicked });
    navigate(`${ERoutes.DELIVERY_CONFERENCE}`, {
      state: { deliveryRoll: clicked }
    });
  };
  return (
    <AppTable
      columns={[{ title: 'Rol de entrega' }, { title: 'Peso' }, { title: '' }]}
      rows={items.map((item) => ({
        tds: [
          { content: item.number },
          { content: formatWeight(item.weight_kg) },
          {
            content: (
              <Button
                name="check-btn"
                variant="primary"
                onClick={() => onCheckClick(item)}
                icon={<Icon option="ArrowRight" />}
                iconPosition="right"
                children="Conferir"
              />
            ),
            align: 'right'
          }
        ],
        expandableContent: <ExpandedTableContent romaneios={item.romaneios} />
      }))}
    />
  );
};

export default ToCheckTable;
