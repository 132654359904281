import { Modal } from '@gerdau/hefesto';
import React from 'react';

interface UseTermsProps {
  onClose: () => void;
}

const UseTerms = (props: UseTermsProps) => {
  // return <div onClick={props.onClose}>teste</div>;
  return (
    <Modal
      title="Política de Privacidade e Termos de Uso"
      buttons={{
        right: { name: 'close', children: 'Fechar', onClick: props.onClose }
      }}
    >
      <div>
        <p>
          <b>A GERDAU S/A (“Gerdau”),</b> pessoa jurídica de direito privado,
          com endereço na Av. Dra. Ruth Cardoso, n. 8501, Andar 8, Conj. 2,
          Pinheiros, São Paulo/SP, inscrita no CNPJ/MF sob n.
          33.611.500/0001-19, estabelece, adiante, a Política de Privacidade do
          App GERDAU PRO, de forma a esclarecer os direitos, obrigações e
          responsabilidades de seus Usuários.
        </p>
        <p>
          <b>1. INFORMAÇÕES GERAIS</b>
        </p>
        <p>
          1.1. A Gerdau disponibiliza aos usuários em geral Aplicativo GERDAU
          PRO com o objetivo de facilitar a conferência dos materiais de Corte &
          Dobra.
        </p>
        <p>
          1.2. Para utilização do aplicativo é necessário que a versão do IOS
          seja maior que: IOS 12.4.
        </p>
        <p>
          1.3. Para funcionamento correto do aplicativo, é necessário que a
          câmera do seu celular esteja habilitada, somente assim será possível
          realizar a conferência das etiquetas.
        </p>
        <p>
          1.4. Inicialmente o App somente conseguirá realizar a conferências das
          etiquetas emitidas pela Gerdau. Estamos avaliando outras formas de
          ajustar o app para conferir também as etiquetas de parceiros da
          Gerdau.
        </p>
        <p>
          1.5. Seguimos em conformidade com a proteção dos seus dados. Para mais
          informações e definições, consultar Lei Geral de Proteção de Dados
          (LGPD) – Lei 13.79/2018.
        </p>
        <p>
          1.6. Para mais informações sobre a Gerdau, consulte nosso site em
          &nbsp;
          <a href="https://www2.gerdau.com.br/" target="_blank">
            https://www2.gerdau.com.br/
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default UseTerms;
