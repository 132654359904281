import React, { useContext, useEffect, useState } from 'react';
import { SearchField, Title } from '@gerdau/hefesto';
import { Container, TabContainer } from './styles';
import Remaining from './Remaining';
import NotFound from './NotFound';
import Found from './Found';
import { useLocation } from 'react-router-dom';
import GoBackButton from '../../../../components/GoBackButton';
import {
  DeliveryRoll,
  Sequence,
  SequenceStatus
} from '../../../../types/business';
import { extractAllSequencesWithStatus } from '../../../../utils/business';
import CDBTabs from '../../../../components/CDBTabs';
import CDBConferenceContext from '../../../../contexts/app';

export interface IConferenceLocationState {
  deliveryRoll: DeliveryRoll;
}

enum EDeliveryConferenceTabs {
  REMAINING = 'remaining',
  NOT_FOUND = 'not_found',
  FOUND = 'found'
}

const DeliveryConference: React.FC = () => {
  const location = useLocation();
  const context = useContext(CDBConferenceContext);
  const [deliveryRoll, setDeliveryRoll] = useState(
    (location.state as IConferenceLocationState).deliveryRoll
  );

  const [searchValue, setSearchValue] = useState('');

  const [foundSequences, setFoundSequences] = useState<Sequence[]>(
    extractAllSequencesWithStatus(deliveryRoll, SequenceStatus.Checked)
  );
  const [missingSequences, setMissingSequences] = useState<Sequence[]>(
    extractAllSequencesWithStatus(deliveryRoll, SequenceStatus.Missing)
  );
  const [remainingSequences, setRemainingSequences] = useState<Sequence[]>(
    extractAllSequencesWithStatus(deliveryRoll, SequenceStatus.NotVerified)
  );

  const [tab, setTab] = useState<EDeliveryConferenceTabs>(
    EDeliveryConferenceTabs.REMAINING
  );

  useEffect(() => {
    const newDr = context.data.delivery_rolls.find(
      (dr) => dr.id === deliveryRoll.id
    );
    if (newDr) {
      setDeliveryRoll(newDr);
      setRemainingSequences(
        extractAllSequencesWithStatus(newDr, SequenceStatus.NotVerified)
      );
      setMissingSequences(
        extractAllSequencesWithStatus(newDr, SequenceStatus.Missing)
      );
      setFoundSequences(
        extractAllSequencesWithStatus(newDr, SequenceStatus.Checked)
      );
    }
  }, [context.computed]);

  return (
    <Container>
      <header>
        <div>
          <GoBackButton />
          <h2>Conferência da entrega</h2>
        </div>

        <div>
          <Title
            level={6}
            children={`Rol de entrega: ${deliveryRoll?.number}`}
          />
          <Title
            level={6}
            children={`Itens: ${
              remainingSequences.length +
              missingSequences.length +
              foundSequences.length
            }`}
          ></Title>
        </div>

        <div className="input-container">
          <SearchField
            label=""
            hideLabel
            placeholder="Buscar por nº de sequência"
            name="search-delivery"
            inputValue={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </header>

      <TabContainer>
        <CDBTabs
          activeKey={tab}
          onChange={(newTab) => setTab(newTab as EDeliveryConferenceTabs)}
          tabItems={[
            {
              key: EDeliveryConferenceTabs.REMAINING,
              name: `Restantes (${remainingSequences.length})`,
              content: (
                <Remaining
                  sequences={remainingSequences}
                  searchValue={searchValue}
                />
              )
            },
            {
              key: EDeliveryConferenceTabs.FOUND,
              name: `Encontrados (${foundSequences.length})`,
              content: (
                <Found sequences={foundSequences} searchValue={searchValue} />
              )
            },
            {
              key: EDeliveryConferenceTabs.NOT_FOUND,
              name: `Não encontrados (${missingSequences.length})`,
              content: (
                <NotFound
                  sequences={missingSequences}
                  deliveryRoll={deliveryRoll}
                  searchValue={searchValue}
                />
              )
            }
          ]}
          tabPosition="top"
        />
      </TabContainer>
    </Container>
  );
};

export default DeliveryConference;
