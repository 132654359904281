import React from 'react';
import { Button, Icon } from '@gerdau/hefesto';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../../..';
import AppTable from '../../../../../../components/AppTable';
import OptionsMenu from '../../../../../../components/OptionsMenu';
import ExpandedTableContent from '../../components/ExpandedTableContent';
import { DeliveryRoll } from '../../../../../../types/business';
import { formatWeight } from '../../../../../../utils/formatValues';

interface ICheckedTableProps {
  deliveryRolls: DeliveryRoll[];
}

const CheckedTable: React.FC<ICheckedTableProps> = ({ deliveryRolls }) => {
  const navigate = useNavigate();
  return (
    <AppTable
      columns={[
        { title: 'Rol de entrega' },
        { title: 'Peso' },
        { title: 'Itens' },
        { title: '' }
      ]}
      rows={deliveryRolls.map((item) => ({
        tds: [
          { content: item.number },
          { content: formatWeight(item.weight_kg) },
          { content: item.romaneios.length },
          {
            content: (
              <OptionsMenu
                items={[
                  // {
                  //   title: 'Ver pedido',
                  //   onClick: () => navigate(ERoutes.ORDER_HISTORY)
                  // },
                  {
                    title: 'Abrir manifestação',
                    onClick: () =>
                      navigate(ERoutes.OPEN_TICKET, {
                        state: { deliveryRoll: item }
                      })
                  }
                ]}
              >
                <Button
                  name="check-btn"
                  variant="tertiary"
                  icon={<Icon option="MoreVertical" />}
                  iconPosition="right"
                  children=""
                />
              </OptionsMenu>
            ),
            align: 'right'
          }
        ],
        expandableContent: <ExpandedTableContent romaneios={item.romaneios} />
      }))}
    />
  );
};

export default CheckedTable;
