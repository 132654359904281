import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.colors.secondary_color_03_50};
  padding-bottom: 2.5rem;
  overflow: auto;

  & > header {
    padding: 1rem;
    background: ${({ theme }) => theme.colors.secondary_color_03_50};

    & > div {
      display: flex;
      align-items: center;

      & > h2 {
        font-size: 1.5rem;
        font-weight: 600;
        font-family: ${({ theme }) => theme.fonts.sourceSansPro};
        color: ${({ theme }) => theme.colors.secondary_color_02_500};
        margin-bottom: 0.25rem;
      }
    }
  }
`;
