import React, { useContext, useEffect, useState } from 'react';
import { Container, Footer, UseTermsContainer } from './styles';
import { Button, TextField } from '@gerdau/hefesto';
import { isValidEmail } from '../../../../utils/inputValidator';
import { svg } from '../../../../assets/styles/images/logo';
import Sdk from '../../../../sdk';
import { AUTH_TOKEN_STORAGE_KEY } from '../../../../constants/storage';
import CDBConferenceContext from '../../../../contexts/app';
import {
  ENotificationTypes,
  useNotifications
} from '../../../../contexts/notifications';
import UseTerms from './UseTerms';
import { Link } from 'react-router-dom';

interface ILoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const context = useContext(CDBConferenceContext);
  const notification = useNotifications();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<ILoginFormValues>({
    email: '',
    password: ''
  });

  const [canShowUseTerms, setCanShowUseTerms] = useState(false);

  const handleChangeInputFields = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    function loadToken(): Promise<string | null> {
      return new Promise((res) =>
        res(localStorage.getItem(AUTH_TOKEN_STORAGE_KEY))
      );
    }

    function downloadProfileIfTokenExists(token: string | null) {
      if (token) {
        Sdk.loadProfile();
        context.auth.loginAs({
          name: 'user',
          email: 'user@email.com'
        });
      }
    }

    loadToken().then(downloadProfileIfTokenExists);
  }, []);

  async function authenticate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      setLoading(true);
      const { email, password } = formValues;
      const response = await Sdk.login(email, password);
      const token = response.token;
      localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify(token));
      // consultar /profile para conseguir nome e etc
      await context.auth.loginAs({
        name: '',
        email: email
      });
    } catch (error) {
      console.error({ error });
      notification.addNotification(
        'Erro ao fazer login',
        ENotificationTypes.DANGER
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      {svg}
      <span>Utilizar o mesmo login da Gerdau mais</span>
      <form onSubmit={authenticate}>
        <TextField
          type="text"
          name="email"
          label="Email"
          placeholder="Insira o seu e-mail"
          value={formValues.email}
          onChange={handleChangeInputFields}
          variant="neutral"
          disabled={loading}
        />
        <TextField
          type="password"
          name="password"
          label="Senha"
          placeholder="Insira a sua senha"
          value={formValues.password}
          onChange={handleChangeInputFields}
          variant="neutral"
          disabled={loading}
        />
        <div className="actions">
          {/* <span>Esqueci a senha</span> */}
          <Button
            name="submit"
            type="submit"
            variant="secondary"
            iconPosition={loading ? 'left' : 'right'}
            loading={loading}
            disabled={
              loading || !formValues.password || !isValidEmail(formValues.email)
            }
          >
            Entrar
          </Button>
        </div>
      </form>
      <UseTermsContainer>
        <span>Ao continuar, você concorda com a</span>
        <br />
        <a onClick={() => setCanShowUseTerms(true)}>
          Política de Privacidade e Termos de Uso.
        </a>

        {canShowUseTerms && (
          <UseTerms onClose={() => setCanShowUseTerms(false)} />
        )}
      </UseTermsContainer>
      <Footer>
        <span>Saiba mais em</span>
        <Link to="https://mais.gerdau.com.br/gerdaupro/">
          mais.gerdau.com.br/gerdaupro/
        </Link>
      </Footer>
    </Container>
  );
};

export default Login;
