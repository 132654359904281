import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 2.4rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.colors.secondary_color_03_100};

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    & > button {
      height: unset;
      padding: 0;
    }
  }

  & > span.description {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.primary_color_01_800};
    margin-top: 0.5rem;
    font-weight: 400;
  }
`;

export const CollapseWithLink = styled.section`
  display: flex;
  align-items: baseline;
  gap: 5px;

  & > span.description {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.primary_color_01_800};
    margin-top: 0.5rem;
    font-weight: 400;
  }

  & > a {
    color: ${({ theme }) => theme.colors.primary_color_02_600};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-decoration: underline;
  }
`;
