import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 3.5rem 1rem;
  gap: 1rem;

  &.is-in-ticket-view {
    padding: 0 0 3.5rem 0;
  }

  & > div.actions {
    position: fixed;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: ${({ theme }) => theme.breakpoints.tablet};
    display: flex;
    gap: 1rem;

    & > button {
      flex-grow: 1;
    }
  }
`;
