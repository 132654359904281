import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    inset: 0;
    background: ${theme.colors.overlay};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .scanner-content {
      width: 80%;

      & > .close-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & > button {
          height: 2rem;
          width: 2rem;
          left: 0.5rem;
          bottom: 0.5rem;
          padding: 0;

          & .hefesto-icon {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .canvas-wrapper {
      position: relative;

      &.captured {
        .border {
          border: 0.5rem solid
            ${({ theme }) => theme.colors.support_color_02_900};
          width: calc(100% + 0.75rem);
          height: calc(100% + 0.625rem);
          transform: translate(-50%, -50%);
          left: 50%;
          top: 49%;
        }
      }
    }

    #interactive.viewport {
      position: relative;
    }

    #interactive.viewport > canvas,
    #interactive.viewport > video {
      max-width: 100%;
      width: 100%;
    }

    canvas.drawing,
    canvas.drawingBuffer {
      position: absolute;
      left: 0;
      top: 0;
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
      #interactive.viewport {
        width: 100%;
        height: auto;
        overflow: hidden;
      }
    }

    .border {
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% - 2px);
      top: -1px;
      left: -1px;
      border: 1px solid ${theme.colors.secondary_color_03_50};
    }

    @media screen and (min-width: ${theme.breakpoints.laptop}) {
      .scanner-content {
        width: 35%;
      }
    }
  `}
`;
