import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const OverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.overlay};
  left: 0;
  top: 0;
`;

const Overlay: React.FC = () => {
  return createPortal(<OverlayContainer />, document.body);
};

export default Overlay;
