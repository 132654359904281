import React from 'react';
import { AppContainer } from './styles';
import Header from '../../../components/Header';
import { Navigate, Route, Routes } from 'react-router-dom';
import BottomMenu from '../../../components/BottomMenu';
import DeliveryConference from './DeliveryConference';
import DeliveryRolls from './DeliveryRolls';
import Checked from './DeliveryRolls/Checked';
import Complains from './DeliveryRolls/Complains';
import ToCheck from './DeliveryRolls/ToCheck';
import OpenTicket from './OpenTicket';
import ItemsTable from './OpenTicket/ItemsTable';
import OrderHistory from './OrderHistory';
import Ticket from './Ticket';
import UserProfile from './UserProfile';
import TicketForm from './OpenTicket/TicketForm';
import { Settings } from './Settings';

export enum ERoutes {
  DELIVERY_ROLLS = '/delivery-rolls',
  DELIVERY_CONFERENCE = '/delivery-conference',
  ORDER_HISTORY = '/order-history',
  OPEN_TICKET = '/open-ticket',
  USER = '/user',
  TICKET = '/ticket',
  SETTINGS = '/settings'
}

export enum EDeliveryRollsRoutes {
  TO_CHECK = 'to-check',
  CHECKED = 'checked',
  COMPLAINS = 'complains'
}

export enum EOpenTicketRoutes {
  ITEMS_TABLE = '',
  FORM = 'ticket-form'
}

function AuthenticatedRoutes() {
  return (
    <AppContainer>
      <Header />
      <div className="app-content">
        <Routes>
          <Route path={ERoutes.DELIVERY_ROLLS} element={<DeliveryRolls />}>
            <Route
              index
              path={EDeliveryRollsRoutes.TO_CHECK}
              element={<ToCheck />}
            />
            <Route path={EDeliveryRollsRoutes.CHECKED} element={<Checked />} />
            <Route
              path={EDeliveryRollsRoutes.COMPLAINS}
              element={<Complains />}
            />
            <Route
              path=""
              element={<Navigate to={EDeliveryRollsRoutes.TO_CHECK} />}
            />
          </Route>

          <Route
            path={ERoutes.DELIVERY_CONFERENCE}
            element={<DeliveryConference />}
          />

          <Route path={ERoutes.ORDER_HISTORY} element={<OrderHistory />} />

          <Route path={ERoutes.OPEN_TICKET} element={<OpenTicket />}>
            <Route
              path={EOpenTicketRoutes.ITEMS_TABLE}
              element={<ItemsTable />}
            />
            <Route path={EOpenTicketRoutes.FORM} element={<TicketForm />} />
          </Route>

          <Route path={ERoutes.USER} element={<UserProfile />} />

          <Route path={ERoutes.TICKET} element={<Ticket />} />

          <Route path={ERoutes.SETTINGS} element={<Settings />} />

          <Route path="*" element={<Navigate to={ERoutes.DELIVERY_ROLLS} />} />
        </Routes>
      </div>
      <BottomMenu />
    </AppContainer>
  );
}

export default AuthenticatedRoutes;
