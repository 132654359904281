import React, { useState } from 'react';
import { Container, Content, ReaderButtonContainer } from './styles';
import { Button, Icon } from '@gerdau/hefesto';
import { useNavigate } from 'react-router-dom';
import Scanner from '../Scanner';
import { ERoutes } from '../../pages/Router/AuthenticatedRoutes';
import { IDeliveryRollsLocationState } from '../../pages/Router/AuthenticatedRoutes/DeliveryRolls';

const BottomMenu = () => {
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);

  return (
    <Container>
      <Content>
        <div>
          <Button
            variant="secondary"
            name="home"
            onClick={() => navigate(ERoutes.DELIVERY_ROLLS)}
          >
            <Icon option="Home" size="sm" />
            <span>Início</span>
          </Button>

          <Button
            variant="secondary"
            name="search"
            onClick={() =>
              navigate(ERoutes.DELIVERY_ROLLS, {
                state: {
                  focusOnSearchField: true
                } as IDeliveryRollsLocationState
              })
            }
          >
            <Icon option="Search" />
            <span>Buscar</span>
          </Button>
        </div>

        <div>
          <Button
            variant="secondary"
            name="settings"
            onClick={() => navigate(ERoutes.SETTINGS)}
          >
            <Icon option="HelpCircle" />
            <span>FAQ</span>
          </Button>

          <Button
            variant="secondary"
            name="profile"
            onClick={() => navigate(ERoutes.USER)}
          >
            <Icon option="Person" />
            <span>Perfil</span>
          </Button>
        </div>

        <ReaderButtonContainer>
          <Button name="reader" onClick={() => setScanning(true)}>
            <Icon option="Barcode" variant="light" />
          </Button>
          {scanning ? (
            <Scanner onCloseClick={() => setScanning(false)} />
          ) : null}
        </ReaderButtonContainer>
      </Content>
    </Container>
  );
};

export default BottomMenu;
