import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > header {
    padding: 1rem;
    background: ${({ theme }) => theme.colors.secondary_color_03_50};

    & > div {
      &:first-child {
        display: flex;
        align-items: center;
      }

      & > h2 {
        font-size: 1.5rem;
        font-weight: 600;
        font-family: ${({ theme }) => theme.fonts.sourceSansPro};
        color: ${({ theme }) => theme.colors.secondary_color_02_500};
        margin-bottom: 0.25rem;
      }
    }

    & > div.input-container {
      display: flex;
      gap: 0.5rem;
      margin: 1rem 0;
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  width: calc(100% + 2rem);
  position: relative;
  right: 1rem;
  padding: 0 1rem 2.5rem 1rem;

  & .ant-tabs-nav-wrap {
    background: ${({ theme }) => theme.colors.secondary_color_03_50};
  }
`;
