import React, { useEffect, useRef, useState } from 'react';
import { Tab } from '@gerdau/hefesto';
import { TabProps } from '@gerdau/hefesto/Tab/Tab.types';
import { Container } from './styles';

type CDBTabsProps = TabProps;

function CDBTabs(props: CDBTabsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const getTabContainerHeight = (): number => {
    const containerPositionY =
      containerRef.current?.getClientRects()[0].top || 0;
    const bodyHeight = document.body.getClientRects()[0].height;
    const bottomHeight = 56;
    return bodyHeight - containerPositionY - bottomHeight;
  };

  useEffect(() => {
    setContainerHeight(getTabContainerHeight());
  }, []);

  const onChangeTab = (activeKey: string) => {
    setContainerHeight(getTabContainerHeight());
    props.onChange && props.onChange(activeKey);
  };

  return (
    <Tab
      {...props}
      fixed
      onChange={onChangeTab}
      tabItems={props.tabItems.map((item) => ({
        ...item,
        content: (
          <Container ref={containerRef} style={{ height: containerHeight }}>
            {item.content}
          </Container>
        )
      }))}
    />
  );
}

export default CDBTabs;
