import React, { useState } from 'react';
import { Container, StatusText } from './styles';
import { SearchField } from '@gerdau/hefesto';
import AppTable from '../../../../components/AppTable';
import GoBackButton from '../../../../components/GoBackButton';
import { Complain, complainStatusMap } from '../../../../types/business';
import { useLocation } from 'react-router-dom';
import TagImage from '../../../../components/TagImage';

interface LocationState {
  complain: Complain;
}

const Ticket: React.FC = () => {
  const location = useLocation();
  const { complain } = location.state as LocationState;

  const [searchText, setSearchText] = useState('');

  return (
    <Container>
      <header>
        <div>
          <GoBackButton />
          <h2>Manifestação</h2>
        </div>

        <div>
          <StatusText>
            Status: <span>{complainStatusMap.get(complain.status)}</span>
          </StatusText>
        </div>

        <div className="input-container">
          <SearchField
            label=""
            hideLabel
            placeholder="Buscar por nº de sequência"
            name="search-delivery"
            inputValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </header>

      <AppTable
        columns={[
          { title: 'Formato' },
          { title: 'Sequência' },
          { title: 'Romaneio' }
        ]}
        rows={complain.sequences
          .filter((sequence) =>
            sequence.number
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
          .map((sequence) => ({
            tds: [
              {
                content: <TagImage id={sequence.picture} />
              },
              { content: sequence.number },
              { content: sequence.romaneio }
            ]
          }))}
      />
    </Container>
  );
};

export default Ticket;
