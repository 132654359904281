import React, { useState } from 'react';
import { Button } from '@gerdau/hefesto';
import { TicketFormProps } from '..';
import { FooterContainer } from './styles';

interface ActionsFooterProps extends TicketFormProps {
  disabledBackButton?: boolean;
  disabledNextButton?: boolean;
  hideBackButton?: boolean;
  lastPage?: boolean;
}

const ActionsFooter = ({
  disabledBackButton,
  disabledNextButton,
  hideBackButton,
  setTicketForm,
  lastPage,
  onFinish
}: ActionsFooterProps) => {
  const [loading, setLoading] = useState(false);

  function onPressed() {
    if (lastPage) {
      setLoading(true);
      onFinish();
    } else {
      setTicketForm((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1
      }));
    }
  }

  return (
    <FooterContainer>
      {!hideBackButton && (
        <Button
          name="open-ticket"
          disabled={disabledBackButton}
          variant="secondary"
          onClick={() =>
            setTicketForm((prev) => ({
              ...prev,
              currentPage: prev.currentPage - 1
            }))
          }
        >
          Voltar
        </Button>
      )}

      <Button
        name="open-ticket"
        disabled={disabledNextButton || loading}
        onClick={onPressed}
        loading={loading}
      >
        {`${lastPage ? 'Enviar manifestação' : 'Avançar'}`}
      </Button>
    </FooterContainer>
  );
};

export default ActionsFooter;
