import React from 'react';
import { AppBar } from '@gerdau/hefesto';
import { AppHeader, HeaderTitle } from './styles';

const Header = () => {
  return (
    <AppHeader>
      <AppBar text="">
        <HeaderTitle>Gerdau PRO</HeaderTitle>
      </AppBar>
    </AppHeader>
  );
};

export default Header;
