import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.primary_color_01_300};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  & > div {
    margin: 0 auto;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  small {
    color: ${(props) => props.theme.colors.secondary_color_03_50};
  }
`;

export const LoadingBar = styled.div`
  position: relative;
  height: 0.25rem;
  width: 90%;
  background-color: #656d73;
  margin: 1rem auto;

  & > div {
    position: absolute;
    height: 0.25rem;
    top: 0px;
    left: 0px;
    width: 3.5rem;
    background-color: ${(props) => props.theme.colors.secondary_color_03_50};
    animation: bar 3s ease-in-out infinite;
  }

  @keyframes bar {
    from {
      left: 0;
      transform: translateX(0);
    }
    50% {
      left: 100%;
      transform: translateX(-100%);
    }
    to {
      left: 0;
      transform: translateX(0);
    }
  }
`;
