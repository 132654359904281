import React from 'react';
import { ToastGroup } from '@gerdau/hefesto';
import { useNotifications } from '../../contexts/notifications';

function Notifications() {
  const { notifications, removeNotification } = useNotifications();

  return (
    <ToastGroup
      onCloseToast={() => undefined}
      toasts={notifications.map((n) => ({
        id: n.id,
        text: n.text,
        variant: n.type,
        actionOne: { label: 'Fechar', onClick: () => removeNotification(n) }
      }))}
    />
  );
}

export default Notifications;
