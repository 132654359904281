import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    background: '#f8f8f8',
    overlay: '#111b2191',
    primary_color_01_300: '#395586',
    primary_color_01_800: '#00000D',
    primary_color_02_600: '#0069AC',
    secondary_color_01_300: '#414E55',
    secondary_color_02_500: '#495961',
    secondary_color_03_100: '#EFEFEF',
    secondary_color_03_50: '#ffffff',
    secondary_color_04_400: '#DEE8EF',
    secondary_color_05_500: '#ECF2F6',
    support_color_02_900: '#477237',
    support_color_05_600: '#7C3E80',
    support_color_alert_dange: '#d71f01'
  },

  fonts: {
    workSans: 'Work Sans',
    sourceSansPro: 'Source Sans Pro'
  },

  breakpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '890px',
    laptopM: '1024px',
    laptopL: '1440px'
  }
};

export default theme;
