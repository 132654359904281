import React, { createContext, useContext, useEffect, useState } from 'react';
import { Container, TabContainer } from './styles';
import { Button, Icon, SearchField, Title } from '@gerdau/hefesto';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { EDeliveryRollsRoutes, ERoutes } from '..';
import CDBConferenceContext from '../../../../contexts/app';
import CDBTabs from '../../../../components/CDBTabs';
import Scanner from '../../../../components/Scanner';

export const DeliveryRollsContext = createContext(
  {} as { searchValue: string }
);

export interface IDeliveryRollsLocationState {
  focusOnSearchField?: true;
}

const DeliveryRolls = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const context = useContext(CDBConferenceContext);

  useEffect(() => {
    if ((state as IDeliveryRollsLocationState)?.focusOnSearchField) {
      const searchField = document.getElementsByName('search-delivery-rolls');
      if (searchField) {
        (searchField[0] as HTMLInputElement).focus();
      }
    }
  }, [state]);

  const [searchValue, setSearchValue] = useState('');

  const [scanning, setScanning] = useState(false);

  const [tab, setTab] = useState<EDeliveryRollsRoutes>(
    EDeliveryRollsRoutes.TO_CHECK
  );
  const onChangeTab = (key: EDeliveryRollsRoutes) => {
    setTab(key);
    navigate(`${ERoutes.DELIVERY_ROLLS}/${key}`);
  };

  const updateTab = () => {
    const paths = location.pathname.split('/');
    const newTab = paths.length > 2 ? paths[2] : EDeliveryRollsRoutes.TO_CHECK;
    setTab(newTab as EDeliveryRollsRoutes);
  };

  useEffect(() => {
    updateTab();
  }, [location.pathname]);

  const [toCheckLenght, setToCheckLenght] = useState<number>(0);
  const [checkedLenght, setCheckedLenght] = useState<number>(0);
  const [complainsLenght, setComplainsLenght] = useState<number>(0);

  useEffect(() => {
    setToCheckLenght(context.computed.toCheck.length);
    setCheckedLenght(context.computed.checked.length);
    setComplainsLenght(context.computed.complains.length);
  }, [context.computed]);

  return (
    <Container>
      <header>
        <h2>Lista de Entregas</h2>

        <Title level={6}>
          Faça a leitura dos códigos de barras ou pesquise abaixo a lista de
          entrega através do número do rol ou romaneio.
        </Title>

        <br />

        <span>Digite ou selecione na relação abaixo.</span>
      </header>

      <div className="input-container">
        <SearchField
          label=""
          hideLabel
          placeholder="Buscar por nº rol ou romaneio"
          name="search-delivery-rolls"
          inputValue={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button
          name="read"
          variant="tertiary"
          icon={<Icon option="Barcode" variant="primary" />}
          onClick={() => setScanning(true)}
        />

        {scanning ? <Scanner onCloseClick={() => setScanning(false)} /> : null}
      </div>

      <TabContainer>
        <DeliveryRollsContext.Provider value={{ searchValue }}>
          <CDBTabs
            activeKey={tab}
            onChange={(newTab) => onChangeTab(newTab as EDeliveryRollsRoutes)}
            tabItems={[
              {
                content: <Outlet />,
                key: EDeliveryRollsRoutes.TO_CHECK,
                name: `A conferir (${toCheckLenght})`
              },
              {
                content: <Outlet />,
                key: EDeliveryRollsRoutes.CHECKED,
                name: `Conferidos (${checkedLenght})`
              },
              {
                content: <Outlet />,
                key: EDeliveryRollsRoutes.COMPLAINS,
                name: `Manifestações (${complainsLenght})`
              }
            ]}
            tabPosition="top"
          />
        </DeliveryRollsContext.Provider>
      </TabContainer>
    </Container>
  );
};

export default DeliveryRolls;
