import React from 'react';
import { TicketFormProps } from '..';
import ActionsFooter from '../ActionsFooter';
import { FieldsContainer } from '../styles';
import { TextArea, TextField } from '@gerdau/hefesto';

function TicketPage4({
  ticketForm,
  setTicketForm,
  onFinish,
  loading
}: TicketFormProps) {
  const isAMaterialIssue =
    ticketForm.q10 !== '' &&
    ticketForm.q10.includes('Material não consta no romaneio');

  function disabledNextButton(): boolean {
    const { q14, q15, q16 } = ticketForm;
    if (isAMaterialIssue) {
      return q14 === '';
    } else {
      return q15 === '' || q16 === '';
    }
  }
  return (
    <>
      <FieldsContainer>
        <h5>{`Problemas com o material - ${
          isAMaterialIssue ? 'Material não consta no romaneio' : 'Detalhes'
        }`}</h5>
        {isAMaterialIssue && (
          <TextField
            name="q14"
            label="Descreva quais materiais (desenhos, elementos estruturais ou posições) deveriam constar no romaneio mas estão faltando. Por exemplo: a viga V1 do desenho 203 não está constando no romaneio e foi solicitada no pedido."
            placeholder="Insira sua resposta"
            value={ticketForm.q14}
            onChange={(e) =>
              setTicketForm((prev) => ({ ...prev, q14: e.target.value }))
            }
            disabled={loading}
          />
        )}

        {!isAMaterialIssue && (
          <>
            <TextField
              name="q15"
              label="Romaneio(s) envolvido(s)"
              placeholder="Insira os romaneio(s) envolvido(s)"
              value={ticketForm.q15}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q15: e.target.value }))
              }
              disabled={loading}
            />
            <TextField
              name="q16"
              label="Sequência(s) envolvida(s)"
              placeholder="Insira a(s) sequência(s) envolvida(s)"
              value={ticketForm.q16}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q16: e.target.value }))
              }
              disabled={loading}
            />
            <TextArea
              name="q17"
              label="Informações adicionais"
              placeholder="Insira sua resposta"
              cols={300}
              value={ticketForm.q17}
              onChange={(e) =>
                setTicketForm((prev) => ({ ...prev, q17: e.target.value }))
              }
              disabled={loading}
            />
          </>
        )}
      </FieldsContainer>

      <ActionsFooter
        ticketForm={ticketForm}
        setTicketForm={setTicketForm}
        disabledBackButton={loading}
        disabledNextButton={loading || disabledNextButton()}
        lastPage
        onFinish={onFinish}
      />
    </>
  );
}

export default TicketPage4;
