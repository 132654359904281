import React, { useContext, useEffect, useState } from 'react';
import Quagga, {
  QuaggaJSCodeReader,
  QuaggaJSResultObject
} from '@ericblade/quagga2';
import { Container } from './styles';
import { createPortal } from 'react-dom';
import { Button, Icon } from '@gerdau/hefesto';
import theme from '../../assets/styles/theme/light';
import {
  ENotificationTypes,
  useNotifications
} from '../../contexts/notifications';
import {
  DecodedTag,
  barTagStringProcessor,
  matchesWithTagStringFormat
} from '../../utils/tag';
import Sdk from '../../sdk';
import CDBConferenceContext from '../../contexts/app';

const readers: QuaggaJSCodeReader[] = [
  // 'code_128_reader',
  // 'ean_reader',
  // 'ean_5_reader',
  // 'ean_2_reader',
  // 'ean_8_reader',
  // 'code_39_reader',
  // 'code_39_vin_reader',
  // 'codabar_reader',
  // 'upc_reader',
  // 'upc_e_reader',
  'i2of5_reader'
  // '2of5_reader',
  // 'code_93_reader',
  // 'code_32_reader'
];

interface IScannerProps {
  onCloseClick: () => void;
}

const sentSequenceIdHash: { [key: string]: { count: number; sent: boolean } } =
  {};
let deviceId: string | null = null;

const Scanner: React.FC<IScannerProps> = ({ onCloseClick }) => {
  const { addNotification } = useNotifications();
  const context = useContext(CDBConferenceContext);

  const [sendingBarcode, setSendingBarcode] = useState(false);

  const onDetected = (result: QuaggaJSResultObject) => {
    const { code } = result.codeResult;

    if (code && !sendingBarcode && matchesWithTagStringFormat(code)) {
      const tag: DecodedTag = barTagStringProcessor(code);
      if (!sentSequenceIdHash[tag.number + tag.date]) {
        sentSequenceIdHash[tag.number + tag.date] = { sent: false, count: 0 };
      }

      sentSequenceIdHash[tag.number + tag.date].count++;

      if (
        sentSequenceIdHash[tag.number + tag.date].count >= 20 &&
        !sentSequenceIdHash[tag.number + tag.date].sent
      ) {
        sentSequenceIdHash[tag.number + tag.date].sent = true;
        setTimeout(() => {
          Quagga.stop();
          sendTag(tag);
        }, 500);
      }
    }
  };

  const startQuagga = (deviceId: string) => {
    console.warn('startQuagga');
    const cleanCanvas = (
      drawingCtx: CanvasRenderingContext2D,
      drawingCanvas: HTMLCanvasElement
    ) => {
      drawingCtx.clearRect(
        0,
        0,
        parseInt(drawingCanvas.getAttribute('width') || ''),
        parseInt(drawingCanvas.getAttribute('height') || '')
      );
    };

    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: 480,
            height: 360,
            facingMode: 'environment',
            deviceId
          }
        },
        locator: {
          patchSize: 'medium',
          halfSample: true
        },
        numOfWorkers: 4,
        decoder: {
          readers: [...readers]
        },

        locate: true
      },
      (err) => {
        if (err) {
          return console.error({ err });
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(onDetected);
    Quagga.onProcessed((result) => {
      const drawingCtx: CanvasRenderingContext2D = Quagga.canvas.ctx.overlay,
        drawingCanvas: HTMLCanvasElement = Quagga.canvas.dom.overlay;

      if (result && result.codeResult && result.codeResult.code) {
        if (result.boxes) {
          cleanCanvas(drawingCtx, drawingCanvas);
          result.boxes
            .filter(function (box) {
              return box !== result.box;
            })
            .forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: theme.colors.support_color_02_900,
                lineWidth: 2
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: theme.colors.primary_color_02_600,
            lineWidth: 2
          });
        }

        Quagga.ImageDebug.drawPath(
          result.line,
          { x: 'x', y: 'y' },
          drawingCtx,
          { color: theme.colors.support_color_alert_dange, lineWidth: 3 }
        );
      } else {
        cleanCanvas(drawingCtx, drawingCanvas);
      }
    });
  };

  const requestAccess = (deviceId: string) => {
    addNotification(
      'Para usar o scanner, permita o acesso à camera.',
      ENotificationTypes.WARNING
    );
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        startQuagga(deviceId);
      })
      .catch(function (err) {
        console.error(err);
        addNotification(
          'Não foi possível acessar a câmera.',
          ENotificationTypes.DANGER
        );
        closeCamera();
      });
  };

  useEffect(() => {
    Quagga.CameraAccess.enumerateVideoDevices()
      .then((devices) => {
        const device = devices[devices.length - 1].deviceId;
        deviceId = device;
        if (deviceId === '') {
          requestAccess(deviceId);
        } else {
          startQuagga(deviceId);
        }
      })
      .catch((err) => {
        addNotification(err.message, ENotificationTypes.DANGER);
        console.error(`${err.name}: ${err.message}`);
        onCloseClick();
      });
    return () => {
      Quagga.offDetected(onDetected);
      Quagga.stop();
    };
  }, []);

  const closeCamera = () => {
    Quagga.stop();
    Quagga.offDetected(onDetected);
    onCloseClick();
  };

  const sendTag = async (tag: DecodedTag) => {
    setSendingBarcode(true);
    const response = await Sdk.check(tag);
    if (!response) {
      console.warn('parei por aqui');
      return;
    }
    if (response.success) {
      const rolls = Object.values(response.changed.new_delivery_roll).flat(1);
      for (const rol of rolls) {
        context.conference.appendDeliveryRoll(rol);
      }

      for (const seq of response.changed.sequences) {
        context.conference.updateSequenceStatus(
          seq.sequence,
          seq.delivery_roll_id,
          seq.romaneio_id
        );
      }

      if (response.failedSequences.length) {
        const first = response.failedSequences[0];
        onSendTagError(
          `Falhou a inserir sequência ${first.number} ${first.date}`,
          tag
        );
      } else if (response.rejectedSequences.length > 0) {
        addNotification(
          `A sequência ${response.rejectedSequences[0].number} não pertence ao seu contrato`,
          ENotificationTypes.WARNING,
          3000
        );
      } else {
        addNotification(
          'Item encontrado com sucesso',
          ENotificationTypes.SUCCESS,
          3000
        );
      }
    } else {
      onSendTagError('Ocorreu um erro, tente novamente', tag);
    }

    setSendingBarcode(false);
    deviceId && startQuagga(deviceId);
  };

  function onSendTagError(message: string, tag: DecodedTag) {
    sentSequenceIdHash[tag.number + tag.date].sent = false;
    addNotification(message, ENotificationTypes.DANGER, 3000);
  }

  return createPortal(
    <Container>
      <div className="scanner-content">
        <div className="close-container">
          <Button
            name="close-camera"
            disabled={sendingBarcode}
            loading={sendingBarcode}
            onClick={closeCamera}
            icon={<Icon option="X" />}
            variant="neutral-inverse"
          />
        </div>
        <div className={`canvas-wrapper ${sendingBarcode ? 'captured' : ''}`}>
          <div className="border" />
          <div id="interactive" className="viewport"></div>
        </div>
      </div>
    </Container>,
    document.body
  );
};

export default Scanner;
