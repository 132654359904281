import React, { useContext } from 'react';
import { Container } from './styles';
import { Avatar, Button, TextField, Title } from '@gerdau/hefesto';
import CDBConferenceContext from '../../../../contexts/app';

const UserProfile = () => {
  const context = useContext(CDBConferenceContext);
  return (
    <Container>
      <header>
        <Avatar userName={context.computed.profile.name} size="md" />
        <Title level={3}>{context.computed.profile.name}</Title>
      </header>

      <div>
        <TextField
          label="Telefone para contato"
          placeholder="(00) 00000-0000"
          name="phone"
          disabled
        />
        <TextField
          label="E-mail para contato"
          placeholder={context.computed.profile.email}
          name="email"
          disabled
        />

        <Button name="logout-btn" onClick={context.auth.logout}>
          Sair
        </Button>
      </div>
    </Container>
  );
};

export default UserProfile;
