import React from 'react';
import GlobalStyle from './assets/styles/global';
import { ThemeProvider } from 'styled-components';
import light from './assets/styles/theme/light';
import '@gerdau/hefesto-design-tokens/css/globals.css';
import '@gerdau/hefesto-design-tokens/css/default/theme-1/light.css';
import Notifications from './components/Notifications';
import { RootProvider } from './contexts/rootProvider';
import EntryPoint from './pages/EntryPoint';

function App() {
  return (
    <ThemeProvider theme={light}>
      <GlobalStyle />
      <RootProvider>
        <Notifications />
        <EntryPoint />
      </RootProvider>
    </ThemeProvider>
  );
}

export default App;
