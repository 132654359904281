import React, { useContext, useState } from 'react';
import { Container } from './styles';
import { Button } from '@gerdau/hefesto';
import { DeliveryConferenceCard } from '../components/DeliveryConferenceCard/styles';
import {
  useNotifications,
  ENotificationTypes
} from '../../../../../contexts/notifications';
import { Sequence, SequenceStatus } from '../../../../../types/business';
import TagImage from '../../../../../components/TagImage';
import Sdk from '../../../../../sdk';
import CDBConferenceContext from '../../../../../contexts/app';

interface IRemainingProps {
  sequences: Sequence[];
  searchValue: string;
}

const Remaining: React.FC<IRemainingProps> = ({ sequences, searchValue }) => {
  function filterSequences(sequence: Sequence): boolean {
    return sequence.number.toString().includes(searchValue);
  }

  return (
    <Container>
      {sequences.filter(filterSequences).map((sequence) => (
        <RemainingCard key={sequence.id} sequence={sequence} />
      ))}

      {sequences.filter(filterSequences).length === 0 && (
        <>Inicie conferindo uma etiqueta de suas entregas</>
      )}
    </Container>
  );
};

interface RemainingCardProps {
  sequence: Sequence;
}

const RemainingCard = ({ sequence }: RemainingCardProps) => {
  const context = useContext(CDBConferenceContext);
  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [sequenceStatus, setSequenceStatus] = useState<SequenceStatus>();

  async function changeSequenceStatus(status: SequenceStatus): Promise<void> {
    try {
      setLoading(true);
      setSequenceStatus(status);
      context.loading.show();
      const response = await Sdk.changeTagStatus(sequence, status);
      if (response.success) {
        context.conference.updateSequenceStatus(
          response.modified_sequence.sequence,
          response.modified_sequence.delivery_roll_id,
          response.modified_sequence.romaneio_id
        );
        switch (response.modified_sequence.sequence.status) {
          case SequenceStatus.Checked:
            addNotification(
              'Item encontrado com sucesso',
              ENotificationTypes.SUCCESS,
              3000
            );
            break;
          case SequenceStatus.Missing:
            addNotification(
              'Item marcado como não encontrado',
              ENotificationTypes.DANGER,
              3000
            );
            break;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error({ error });
      if (error.response?.status !== 401) {
        addNotification(
          'Falha ao conferir o item',
          ENotificationTypes.DANGER,
          3000
        );
      }
    } finally {
      setLoading(false);
      setSequenceStatus(undefined);
      context.loading.hide();
    }
  }
  return (
    <DeliveryConferenceCard key={sequence.id}>
      <div>
        <div>
          <span>
            Sequência: <b>{sequence.number}</b>
          </span>
          <span>
            Quantidade: <b>{sequence.quantity}</b>
          </span>
        </div>

        <div>
          <TagImage id={sequence.picture} />
        </div>
      </div>
      <div className="actions">
        <Button
          name="not-found-btn"
          variant="secondary"
          loading={loading && sequenceStatus === SequenceStatus.Missing}
          disabled={loading}
          onClick={() => changeSequenceStatus(SequenceStatus.Missing)}
        >
          Não encontrado
        </Button>
        <Button
          name="found-btn"
          loading={loading && sequenceStatus === SequenceStatus.Checked}
          disabled={loading}
          onClick={() => changeSequenceStatus(SequenceStatus.Checked)}
        >
          Encontrado
        </Button>
      </div>
    </DeliveryConferenceCard>
  );
};

export default Remaining;
