import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  & > ul {
    list-style-type: none;
    width: 100%;
    margin-bottom: 1rem;

    & > li {
      color: ${({ theme }) => theme.colors.primary_color_01_800};
      font-size: 1rem;
      padding: 0 2.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(227, 228, 228, 0.6);
      }

      & span {
        line-height: 1.5rem;

        &.title {
          font-weight: 600;
        }
      }

      & > div {
        display: flex;
        gap: 3rem;
      }
    }
  }
`;
